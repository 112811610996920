<template>
  <div class="row">
    <div class="col-xxl-12 mb-4">
      <b-button @click="goBack" variant="warning" class="mt-3 mb-5"
        >Anuluj / Wstecz</b-button
      >
      <h2 class="mb-6">Typ pożywki</h2>
      <div class="row">
        <div class="col-xl-2">
          <b-form-select
            v-model="cardJson.type"
            :disabled="this.$route.params.cardId !== undefined"
            :options="[
              {
                value: 'ready',
                text: 'Pożywka gotowa',
              },
              {
                value: 'test',
                text: 'Testy i suplementy',
              },
              {
                value: 'dry',
                text: 'Pożywka Sucha',
              },
            ]"
          ></b-form-select>
        </div>
      </div>
    </div>
    <div class="col-xxl-8">
      <h2 class="mb-6">Dane ogólne:</h2>
      <div class="row">
        <div class="col-xl-3">
          <b-form-group label="Tytuł dokumentu" label-for="title">
            <b-form-input
              id="title"
              placeholder="np. Karta dopuszczenia pożywki"
              v-model="cardJson.name"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Kod dokumentu" label-for="code">
            <b-form-input
              id="code"
              placeholder="np. IT-05.05"
              v-model="cardJson.code"
            ></b-form-input>
          </b-form-group>
        </div>
<!--        <div class="col-xl-3">-->
<!--          <b-form-group label="Data wystawienia dokumentu" label-for="docDate">-->
<!--            <b-form-datepicker-->
<!--              reset-button-->
<!--              type="date"-->
<!--              placeholder="dd.mm.rrrr"-->
<!--              size="md"-->
<!--              :start-weekday="1"-->
<!--              :date-format-options="{-->
<!--                year: 'numeric',-->
<!--                month: 'numeric',-->
<!--                day: 'numeric',-->
<!--              }"-->
<!--              id="docDate"-->
<!--              v-model="cardJson.docDate"-->
<!--            >-->
<!--            </b-form-datepicker>-->
<!--          </b-form-group>-->
<!--        </div>-->
        <div class="col-xl-3">
          <b-form-group label="Status pożywki" label-for="docStage">
            <b-form-select
              v-model="cardJson.status"
              :options="[
                {
                  value: 'new',
                  text: 'Nowa'
                },
                {
                  value: 'in-use',
                  text: 'W użyciu',
                },
                {
                  value: 'finished',
                  text: 'Zakończone',
                },
              ]"
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <component
        :is="forms[cardJson.type]"
        @saveCard="saveCard"
        @savePrepared="markPrepared"
      />
<!--      <ReadySoilForm-->
<!--        @saveCard="saveCard"-->
<!--        @savePrepared="markPrepared"-->
<!--        v-if="cardJson.type === 'ready'"-->
<!--      />-->
<!--      <TestSoilForm-->
<!--        v-if="cardJson.type === 'test'"-->
<!--        @saveCard="saveCard"-->
<!--        @savePrepared="markPrepared"-->
<!--      />-->
<!--      <DrySoilForm-->
<!--        v-if="cardJson.type === 'dry'"-->
<!--        @saveCard="saveCard"-->
<!--        @savePrepared="markPrepared"-->
<!--      />-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ReadySoilForm from "../../../content/soil-card/ReadySoilForm";
import TestSoilForm from "../../../content/soil-card/TestSoilForm";
import DrySoilForm from "../../../content/soil-card/DrySoilForm";
import Swal from "sweetalert2";

import { getCardParamValue } from "../../../../core/services/store/soil.module";

export default {
  name: "SoilCardsAdd",
  components: { DrySoilForm, TestSoilForm, ReadySoilForm },
  methods: {
    async goBack() {
      if (this.cloneCard === true && this.$route.params.cardId !== undefined) {
        if (!confirm('Czy na pewno chcesz usunąć tą pożywkę?'))
          return;

        let id = this.$route.params.cardId;
        await this.$store.dispatch("soilCardDelete", { id });
        await this.$store.commit('setCloneCard', false);
      }

      this.$router.push("/soilCards");
    },
    getPayload(formData) {
      return {
        name: this.cardJson.name,
        serial: "n/a",
        code: this.cardJson.code,
        expiry_date: "01-01-1970",
        preparation_date: "01-01-1970",
        open_date: "01-01-1970",
        test_date: "01-01-1970",
        title: this.cardJson.name,
        meta_data: { ...this.cardJson, ...formData },
        stage: this.cardJson.stage,
        status: this.cardJson.status,
        type: this.cardJson.type,
      };
    },
    saveCard(formData) {
      let payload = this.getPayload(formData);

      if ( 
        !this.$route.params.cardId
        && 
        this
          .soilCardsAll
          .some( 
            el => getCardParamValue(payload, "soilMark") == getCardParamValue(el, "soilMark") 
          ) 
      ) {
        // PM NOT UNIQUE
        Swal.fire("Błąd!", "Podane PM już istnieje, proszę podać inne.", "error");
        return;
      }

      if (this.$route.params.cardId)
        this.$store.dispatch("soilCardEdit", {
          id: this.$route.params.cardId,
          ...payload,
        });
      else this.$store.dispatch("soilCardAdd", payload);

      this.$store.dispatch("soilCardsList");

      this.$router.push("/soilCards");
    },
    markPrepared(formData) {
      let payload = this.getPayload(formData);

      payload.stage = "prepared";
      payload.meta_data.stage = "prepared";

      this.$store.dispatch("soilCardEdit", {
        id: this.$route.params.cardId,
        ...payload,
      });

      this.$store.dispatch("soilCardsList");

      this.$router.push("/soilCards");
    },
  },
  data() {
    return {
      forms: {
        ready: "ReadySoilForm",
        test: "TestSoilForm",
        dry: "DrySoilForm"
      },
      cardJson: {
        name: "",
        code: "",
        docDate: "",
        stage: "bought",
        status: "new",
        type: "ready",
      },
    };
  },
  watch: {
    soilCardsAll: {
      handler() {
        let candidate = null;
        // let breakLoop = false;
        // need to filter through children and children children as well, or rather search in them
        
        // for( const lv1 of this.soilCardsAll )
        // {
        //   if ( lv1.id == this.$route.params.cardId )
        //   {
        //     candidate = lv1;
        //     break;
        //   }
        //   if ( lv1.children.length > 0 ) {
        //     for( const lv2 of lv1.children )
        //     {
        //       if ( lv2.id == this.$route.params.cardId )
        //       {
        //         candidate = lv2;
        //         breakLoop = true;
        //         break;
        //       }

        //       if ( lv2.children.legnth > 0 ) {
        //         for( const lv3 of lv2.children )
        //         {
        //           if ( lv3.id == this.$route.params.cardId )
        //           {
        //             candidate = lv3;
        //             breakLoop = true;
        //             break;
        //           }
        //         }
        //       }

        //       if ( breakLoop )
        //         break;
        //     }
        //   }

        //   if ( breakLoop )
        //     break;
        // }

        candidate = this.soilCardsAll.filter( el => el.id == this.$route.params.cardId )[0]
        
        if (candidate) this.cardJson = candidate.meta_data;
      },
      immediate: true,
    },
    "cardJson.type": function () {
      // Set Default values
      if (this.cardJson.type === "ready") {
        this.cardJson.name = "Karta pożywki gotowej";
        this.cardJson.code = "IT-05.04";
      } else if (this.cardJson.type === "test") {
        this.cardJson.name = "Karta kontroli testów i suplementów";
        this.cardJson.code = "IT-05.05";
      } else if ( this.cardJson.type === 'dry') {
        this.cardJson.name = "Karta przygotowania i dopuszczenia pożywki suchej";
        this.cardJson.code = "IT-05.03";
      }
    },
  },
  computed: {
    ...mapGetters(["soilCards", "soilCardsAll", "currentUserPersonalInfo", "cloneCard"]),
    user() {
      if (this.currentUserPersonalInfo.name && !this.$route.params.cardId)
        return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
      else if (this.$route.params.cardId)
        return this.soilCardsAll.filter(
          (el) => el.id == this.$route.params.cardId
        )[0]
          ? this.soilCardsAll.filter((el) => el.id == this.$route.params.cardId)[0]
              .user.firstName +
              " " +
              this.soilCardsAll.filter(
                (el) => el.id == this.$route.params.cardId
              )[0].user.lastName
          : "";

      return "";
    },
  },
  mounted() {
    this.$store.dispatch("soilCardsList");

    if (this.cardJson.type === "ready") {
      this.cardJson.name = "Karta pożywki gotowej";
      this.cardJson.code = "IT-05.04";
    }
  },
};
</script>

<style lang="scss" scoped>
.colVerticalCenter {
  display: grid;
  align-items: center;
}
table th,
table td {
  padding: 5px 10px;
}

table tr {
  border-bottom: 1px solid #000;
}
table td {
  border-right: 1px solid #000;
}
table tr td:last-of-type {
  border-right: none;
}

.dataTable {
  header {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;

    div {
      padding: 5px 0;
      font-weight: bold;
    }
  }
  header,
  .tableBody {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 10px;

    .tableRow {
      border-bottom: 1px solid #000;
      padding: 5px 0 20px;
      grid-column: 1 / span 8;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      align-items: center;

      &:last-of-type {
        border-bottom: none;
      }

      .tableSubRow {
        grid-column: 3 / span 5;
        display: grid;
        grid-template-columns: 30px repeat(5, 1fr);
        grid-column-gap: 10px;
      }
    }
  }
}

.span6 {
  grid-column: 2 / span 6;
}

.addSubRow {
}
</style>
