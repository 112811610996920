
let getValueByPath = (obj, path) => {
    let keys = path.split('.');
    let currentValue = obj;

    for (let key of keys) {
        if (currentValue[key] === undefined) {
            return undefined;   // or throw an error, depending on your needs
        }
        currentValue = currentValue[key];
    }


    return currentValue;
}

let assureLabels = ( arr, jsonObj, keyPath ) => {
    let keyLabels = {};
    let v = getValueByPath(jsonObj, keyPath);

    if ( typeof v === 'undefined' )
        return;

    for( const el of v )
        keyLabels[ el.key ] = el.label;

    arr = arr.map( el => {
        el.label = keyLabels[ el.key ];

        return el;
    })
};

let assureProps = ( arr, jsonObj, keyPath ) => {
    let keyProps = {};
    let v = getValueByPath(jsonObj, keyPath);

    if ( typeof v === 'undefined' )
        return;

    for ( const el of v )
        if ( el.props )
            keyProps[ el.key ] = el.props;
    
    arr = arr.map( el => {
        if ( el.props )
            el.props = keyProps[ el.key ];

        return el;
    } )
};

let removeObjects = ( arr, jsonObj, keyPath ) => {
    let v = getValueByPath(jsonObj, keyPath);

    if ( typeof v === 'undefined' )
        return;

    let jsonKeys = [ ...v ].map( el => el.key );
    let keysToRemove = arr.map( el => el.key ).filter( el => !jsonKeys.includes( el ) );

    arr = arr.filter( el => !keysToRemove.includes( el.key ) );
};

let addObjects = ( arr, jsonObj, keyPath ) => {
    let arrKeys = arr.map( el => el.key );
    let v = getValueByPath(jsonObj, keyPath);

    if ( typeof v === 'undefined' )
        return;

    v
        .filter( el => !arrKeys.includes( el.key ) )
        .forEach( el => arr.push(el) );
};

let assureRowCompliance = ( arr, jsonObj, keyPath ) => {
    let p = keyPath.join('.');
    // Check if both arrays have the same keys
    if (
        JSON.stringify(
            arr.map( el => el.key ).sort()
        )
        ==
        JSON.stringify(
            [getValueByPath(jsonObj, p)].filter( el => typeof  el !== "undefined" ).map( el => el.key ).sort()
        )
    )   
    {
        assureLabels( arr, jsonObj, p );
        assureProps( arr, jsonObj, p );
    }
    else
    {
        assureLabels( arr, jsonObj, p );

        assureProps( arr, jsonObj, p );
        
        removeObjects( arr, jsonObj, p );

        addObjects( arr, jsonObj, p );
    }
};

let hasRows = ( obj ) => {
    if ( typeof obj !== 'object' || null === obj )
        return false;

    return Object.keys(obj).includes('value') && Object.keys(obj).includes('key');
};

let checkObject = ( obj, jsonObj, keyPath = [] ) => {
    for (let key of Object.keys(obj)) {
        // Update the keyPath for the current level
        keyPath.push(key);

        // Check if the current property has "rows"
        if (hasRows(obj[key])) {
            assureRowCompliance(obj, jsonObj, keyPath.slice(0, -1));
        } else if (obj[key] && typeof obj[key] === 'object') {
            // Recursive call for nested objects
            checkObject(obj[key], jsonObj, keyPath);
        }

        // Remove the current key from the path as we move back up
        keyPath.pop();
    }
};

export default checkObject;