<template>
  <div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div
            class="col-xl-3"
            v-for="(field, index) in this.cardJson.acceptance.rows.filter((el) =>
            ['soilMark', 'soilShortcut', 'soilName'].includes(el.key)
          )"
            v-bind:key="'some' + index"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component
                v-bind:is="field.component"
                v-bind="field.props"
                v-model="field.value"
            ></component>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">Protokół przyjęcia pożywki:</h2>
      <b-form-checkbox 
        v-model="cardJson.acceptance.accepted"
        @change="markUser('acceptance.accepted', 'acceptance.person', 'acceptance.date' )"
      >Przyjęto</b-form-checkbox>
      <b-form-group label="Osoba przyjmująca podłoże:">
        <b-form-input
          v-model="cardJson.acceptance.person"
        />
      </b-form-group>
      <div class="row">
        <div
          v-for="(field, index) in this.cardJson.acceptance.rows.filter(
            (el) => !['soilMark', 'soilShortcut', 'soilName'].includes(el.key)
          )"
          v-bind:key="index"
          class="col-xl-3"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
            ></component>
          </b-form-group>
        </div>

        <div class="col-xxl-3 colVerticalCenter">
          <b-form-group label="Certyfikat">
            <b-form-radio
              v-model="cardJson.acceptance.certified"
              name="certified"
              value="true"
              >Tak</b-form-radio
            >
            <b-form-radio
              v-model="cardJson.acceptance.certified"
              name="certified"
              value="false"
              >Nie</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="col-xxl-3 colVericalCenter">
          <b-form-group label="Ocena opakowania">
            <b-form-radio
              v-model="cardJson.acceptance.satisfies.value"
              name="satisfies"
              value="true"
              >Spełnia oczekiwania</b-form-radio
            >
            <b-form-radio
              v-model="cardJson.acceptance.satisfies.value"
              name="satisfies"
              value="false"
              >Nie spełnia oczekiwań</b-form-radio
            >
            <b-form-input
              placeholder="Uwagi"
              v-model="cardJson.acceptance.satisfies.comment"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <h2 class="mb-6 mt-6">Protokół przygotowania pożywki:</h2>
      <b-form-checkbox 
        v-model="cardJson.prep.accepted"
        @change="markUser('prep.accepted', 'prep.person', 'prep.date' )"
      >Przygotowano</b-form-checkbox>
      <b-form-group label="Osoba przygotowująca podłoże:">
        <b-form-input
            v-model="cardJson.prep.person"
        />
      </b-form-group>
      <div class="row">
        <div
          v-for="(field, index) in this.cardJson.prep.rows"
          v-bind:key="index"
          class="col-xl-3"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component 
              v-if="field.key === 'soilPrepedVolume'"
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
              v-on:change="calculateNawazka( field.key, 'prep' )"
            />
            <component
              v-else
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
              v-on:change="calculateExpiryDate(field.key, 'prep')"
              v-on:input="calculateExpiryDate(field.key, 'prep')"
            ></component>
          </b-form-group>
        </div>
      </div>
      <h3>Dodane suplementy</h3>
      <div class="row">
        <div class="col-xl-3">
          <b-form-group>
            <b-form-radio
              v-model="cardJson.prep.suplements.added"
              name="supplements"
              value="true"
              >Tak</b-form-radio
            >
            <b-form-radio
              v-model="cardJson.prep.suplements.added"
              name="supplements"
              value="false"
              >Nie</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-button @click="addSuplement" variant="primary" class="mt-3"
            >Dodaj suplement</b-button
          >
        </div>
      </div>

      <div
        class="row"
        v-for="(rows, rowindex) in this.cardJson.prep.suplements.data"
        v-bind:key="'rou' + rowindex"
      >
        <div v-for="(field, index) in rows" v-bind:key="index" class="col-xl-4">
          <b-form-group
            :label="field.label"
            :label-for="field.key"
            v-if="field.key != 'soilSuplSeries'"
          >
            <component
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
            ></component>
          </b-form-group>
          <b-form-group
            :label="field.label"
            :label-for="field.key"
            v-else
          >
            <v-select
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
              :items="suplementInputs"
              v-on:change="$event => setSuplement( rowindex, field.value )"
            ></v-select>
          </b-form-group>
        </div>
        <div v-bind:key="'rowIndex-' + rowindex" class="col-xl-12">
          <hr />
        </div>
      </div>

      <h3>Warunki sterylizacji</h3>
      <div class="row">
        <div class="col-xl-4">
          <b-form-group label="Sposób jałowienia podłoża">
            <b-form-input
              v-model="cardJson.prep.arridity.type"
              placeholder="np. Łaźnia wodna - kod łaxni: WP--01.03/1"
            />
          </b-form-group>
        </div>
        <div class="col-xl-4">
          <b-form-group label="Parametry ( temperatura/czas )">
            <b-form-input
              v-model="cardJson.prep.arridity.params"
              placeholder="np. 100C/1 min"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">
        Protokół badania cech fizycznych i chemicznych pożywki
      </h2>
      <div class="row">
        <div class="col-xxl-8">
          <div class="row">
            <div class="col-xxl-12">
              <div class="row mb-6">
                <div class="col-xl-2">
                  <b-form-checkbox 
                    v-model="cardJson.chemTest.accepted"
                    @change="markUser('chemTest.accepted', 'chemTest.user', 'chemTest.date' )"
                  >Sprawdzono</b-form-checkbox>
                </div>    
                <div class="col-xl-4">
                  <p class="mb-1"><b>Osoba sprawdzająca podłoże: </b></p>
                  <b-form-input v-model="this.cardJson.chemTest.user" />
                </div>
                <div class="col-xl-4">
                  <p class="mb-1"><b>Data: </b></p> 
                  <b-form-datepicker
                    reset-button
                    type="date"
                    placeholder="dd.mm.rrrr"
                    size="xs"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    id="chemtestDate"
                    v-model="cardJson.chemTest.date"
                  >
                  </b-form-datepicker>
                </div>
              </div>

              <div class="row">
                <div class="col-xxl-4">
                  <b>Parametry badane:</b>
                </div>
                <div class="col-xxl-4">
                  <b>Parametry oczekiwane:</b>
                </div>
                <div class="col-xxl-4">
                  <b>Parametry uzyskane:</b>
                </div>
              </div>

              <div
                v-for="(row, index) in this.cardJson.chemTest.rows"
                class="row"
                v-bind:key="index"
              >
                <div class="col-xxl-4 colVerticalCenter">
                  {{ row.parameter }}
                </div>
                <div class="col-xxl-4 colVerticalCenter">
                  <b-form-input
                    placeholder=""
                    v-model="row.expected"
                  ></b-form-input>
                </div>
                <div class="col-xxl-4 colVerticalCenter">
                  <b-form-group>
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="true"
                      >Tak</b-form-radio
                    >
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="false"
                      >Nie</b-form-radio
                    >
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="n/a"
                      >Nie dotyczy</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-2 mt-6">
        Protokół badania jakości mikrobiologicznej pożywki:
      </h2>
      <h3 class="mb-6 mt-1">Protokół jałowości podłoża</h3>
      <!--      <div class="row">-->
      <!--        <div-->
      <!--          class="col-xl-3"-->
      <!--          v-for="(field, index) in cardJson.bioQuality.general"-->
      <!--          v-bind:key="index"-->
      <!--        >-->
      <!--          <b-form-group :label="field.label" :label-for="field.key">-->
      <!--            <component-->
      <!--              v-bind:is="field.component"-->
      <!--              v-bind="field.props"-->
      <!--              v-model="field.value"-->
      <!--            ></component>-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        class="row"-->
      <!--        v-for="(row, index) in cardJson.bioQuality.rows"-->
      <!--        v-bind:key="index"-->
      <!--      >-->
      <!--        <div class="col-xl-12">-->
      <!--          <h3>{{ row.param }}</h3>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="col-xl-2"-->
      <!--          v-for="(field, index) in row.fields"-->
      <!--          v-bind:key="index"-->
      <!--        >-->
      <!--          <b-form-group :label="field.label" :label-for="field.key">-->
      <!--            <component-->
      <!--              v-bind:is="field.component"-->
      <!--              v-bind="field.props"-->
      <!--              v-model="field.value"-->
      <!--            ></component>-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="row">-->
      <!--        <div class="col-xl-4">-->
      <!--          <b-form-group label="Dopuszczenie do użytku">-->
      <!--            <b-form-radio-->
      <!--              v-model="cardJson.bioQuality.allowed"-->
      <!--              name="allowedKey"-->
      <!--              value="true"-->
      <!--              >Tak</b-form-radio-->
      <!--            >-->
      <!--            <b-form-radio-->
      <!--              v-model="cardJson.bioQuality.allowed"-->
      <!--              name="allowedKey"-->
      <!--              value="false"-->
      <!--              >Nie</b-form-radio-->
      <!--            >-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--        <div class="col-xl-4">-->
      <!--          <b-form-group label="Data">-->
      <!--            <b-form-input-->
      <!--              placeholder="np. 12.12.2021"-->
      <!--              v-model="cardJson.bioQuality.date"-->
      <!--            />-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--        <div class="col-xl-4">-->
      <!--          <b-form-group label="Osoba wprowadzająca">-->
      <!--            <b-form-input placeholder="" v-model="cardJson.bioQuality.person" />-->
      <!--          </b-form-group>-->
      <!--        </div>-->
      <!--      </div>-->
      <section class="dataTable">
        <header>
          <div>Parametry badane</div>
          <div>Parametry inkubacji</div>
          <div>Ilość sprawdzanych płytek / probówek / sztuk</div>
          <div>Ilość kontaminacji</div>
          <div>Ocena wizualna</div>
          <div>Spełnia oczekiwania / nie spełnia oczekiwań</div>
          <div>Data / sprawdził</div>
        </header>

        <section class="tableBody">
          <div
            class="tableRow"
            v-for="(row, index) in this.cardJson.bioQuality.rows"
            v-bind:key="'bio-' + index"
          >
            <div>
              {{ row.tested }}
              <b-form-group label="" v-if="index !== 'other'">
                <b-form-checkbox v-model="row.done">Wykonano</b-form-checkbox>
                <b-form-datepicker
                  v-model="row.dateDone"
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
                <b-form-input placeholder="Wykonał" v-model="row.userDone" />
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Temp. [℃]">
                <b-form-input placeholder="" v-model="row.incubation.temp" />
              </b-form-group>
              <b-form-group label="Czas [h]">
                <b-form-input
                  type="number"
                  label="Czas"
                  placeholder=""
                  v-model="row.incubation.time"
                />
              </b-form-group>
            </div>
            <div>
              <b-form-input v-model="row.testedAmount" />
            </div>
            <div>
              <b-form-input v-model="row.contamination" />
            </div>
            <div>
              <b-form-input placeholder="" v-model="row.visual" />
            </div>
            <div>
              <b-form-select
                :options="[
                  {
                    value: true,
                    text: 'Spełnia oczekiwania',
                  },
                  {
                    value: false,
                    text: 'Nie spełnia oczekiwań',
                  },
                ]"
                v-model="row.grade"
              />
            </div>
            <div>
              <b-form-checkbox 
                v-model="row.accepted"
                @change="markUser('bioQuality.rows.'+index+'.accepted', 'bioQuality.rows.'+index+'.user', 'bioQuality.rows.'+index+'.date' )"
              >Sprawdzono</b-form-checkbox>
              <b-form-input v-model="row.user" />
              <b-form-datepicker
                reset-button
                type="date"
                placeholder="dd.mm.rrrr"
                size="xs"
                :start-weekday="1"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                :id="index + 'specificDate'"
                v-model="row.date"
              >
              </b-form-datepicker>
            </div>
          </div>
        </section>
      </section>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">
        Protokół żyzności, selektywnośći, specyfizcności pożywki:
      </h2>

      <section class="dataTable">
        <header>
          <div>Parametry badane</div>
          <div>Parametry inkubacji</div>
          <div>Szczep testowy użyty do badania</div>
          <div>Ocena wizualna</div>
          <div>Pożywka referencyjna</div>
          <div>Współczynnik żyzności <b>P</b><sub>R</sub></div>
          <div>Spełnia oczekiwania / nie spełnia oczekiwań</div>
          <div>Data / sprawdził</div>
        </header>
        <section class="tableBody">
          <div
            v-for="(row, index) in this.cardJson.fertilityTest"
            v-bind:key="'fertilityTest' + index"
            class="tableRow"
          >
            <div>
              {{ row.tested }}
              <b-form-group label="" v-if="index !== 'other'">
                <b-form-checkbox v-model="row.done">Wykonano</b-form-checkbox>
                <b-form-datepicker
                  v-model="row.dateDone"
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
                <b-form-input placeholder="Wykonał" v-model="row.userDone" />
              </b-form-group>
            </div>
            <template v-if="index !== 'other'">
              <div>
                <b-form-group label="Temp. [℃]">
                  <b-form-input placeholder="" v-model="row.incubation.temp" />
                </b-form-group>
                <b-form-group label="Czas [h]">
                  <b-form-input
                    type="number"
                    label="Czas"
                    placeholder=""
                    v-model="row.incubation.time"
                  />
                </b-form-group>
              </div>
              <section
                class="tableSubRow"
                v-for="(subRow, subIndex) in row.strains"
                v-bind:key="'sub' + subIndex"
              >
                <div>
                  <a
                    class="btn btn-icon btn-light btn-sm addSubRow"
                    @click="addStrain(index)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Code/Plus.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-light btn-sm removeSubRow"
                    v-if="row.strains.length > 1"
                    @click="removeStrain(index, subIndex)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </div>
                <div>
                  <b-form-textarea
                    placeholder="np. Listeria monocytogenes 4b WDCM 00021"
                    v-model="subRow.strain"
                    rows="1"
                  />
                </div>
                <div>
                  <b-form-textarea
                    placeholder="np. Brak wzrostu"
                    v-model="subRow.visual"
                    rows="1"
                  />
                </div>
                <div>
                  <!--                  <b-form-input-->
                  <!--                    placeholder="np. Nie dotyczy"-->
                  <!--                    v-model="subRow.reference"-->
                  <!--                  />-->
                  <b-form-select
                    v-model="subRow.reference"
                    :options="cardsInputValues"
                  />
                </div>
                <div>
                  <b-form-textarea
                    rows="1"
                    placeholder="np. Nie dotyczy"
                    v-model="subRow.fertility"
                  />
                </div>
                <div>
                  <b-form-select
                    :options="[
                      {
                        value: true,
                        text: 'Spełnia oczekiwania',
                      },
                      {
                        value: false,
                        text: 'Nie spełnia oczekiwań',
                      },
                    ]"
                    v-model="subRow.grade"
                  />
                </div>
              </section>
              <div>
                <b-form-checkbox 
                    v-model="row.checked"
                    @change="markUser('fertilityTest.'+index+'.checked', 'fertilityTest.'+index+'.user', 'fertilityTest.'+index+'.date' )"
                  >Sprawdzono</b-form-checkbox>
                <b-form-input v-model="row.user" />
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :id="index + 'specificDate'"
                  v-model="row.date"
                >
                </b-form-datepicker>
              </div>
            </template>
            <template v-if="index === 'other'">
              <div class="span6">
                <b-form-textarea v-model="row.value" />
              </div>
              <div>
                <b-form-checkbox 
                    v-model="row.checked"
                    @change="markUser('fertilityTest.'+index+'.checked', 'fertilityTest.'+index+'.user', 'fertilityTest.'+index+'.date' )"
                  >Sprawdzono</b-form-checkbox>
                <b-form-input v-model="row.user" />
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="otherspecificDate"
                  v-model="row.date"
                >
                </b-form-datepicker>
              </div>
            </template>
          </div>
        </section>
      </section>

      <div class="row">
        <div class="col-xl-3">
          <b-form-group label="Dopuszczono do użytku">
            <b-form-radio
              v-model="cardJson.additional.allowedForUsage"
              name="allowedForUsage"
              value="true"
              >Tak</b-form-radio
            >
            <b-form-radio
              v-model="cardJson.additional.allowedForUsage"
              name="allowedForUsage"
              value="false"
              >Nie</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Data dopuszczenia">
            <b-form-datepicker
              v-model="cardJson.additional.dateAllowed"
              reset-button
              type="date"
              placeholder="dd.mm.rrrr"
              size="xs"
              :start-weekday="1"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Dopuścił">
            <b-form-checkbox
              v-model="cardJson.additional.allowedChecked"
              @change="marUserEnd('additional.allowedChecked', 'additional.userAllowed')"
            >Dopuść</b-form-checkbox>
            <b-form-input v-model="cardJson.additional.userAllowed" />
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Zatwierdził">
            <b-form-checkbox
              v-model="cardJson.additional.acceptedChecked"
              @change="marUserEnd('additional.acceptedChecked', 'additional.whoAllowed')"
            >Zatwierdź</b-form-checkbox>
            <b-form-input v-model="cardJson.additional.whoAllowed" />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <b-button @click="saveCard" variant="primary" class="mt-3"
        >Zapisz pożywkę</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCardParamValue } from "../../../core/services/store/soil.module";
import checkObject from '@/core/services/checkObject'

export default {
  name: "DrySoilForm",
  emits: ["saveCard", "savePrepared"],
  methods: {
    setValueByNestedKey(obj, nestedKey, value) {
      const keys = nestedKey.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, key) => {
        // Check if the key is a numerical index and whether it points to an array
        if (!isNaN(key) && (Array.isArray(o))) {
          // Ensure the array is large enough
          key = parseInt(key, 10);
          while (key >= o.length) {
            o.push({});
          }
          return o[key];
        }
        // If not, proceed with an object
        o[key] = o[key] || {};
        return o[key];
      }, obj);  
      lastObj[lastKey] = value;
      this.$forceUpdate();
    },
    getValueByNestedKey(obj, nestedKey) {
      const keys = nestedKey.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, key) => {
        // Check if the key is a numerical index and whether it points to an array
        if (!isNaN(key) && (Array.isArray(o))) {
          // Ensure the array is large enough
          key = parseInt(key, 10);
          while (key >= o.length) {
            o.push({});
          }
          return o[key];
        }
        // If not, proceed with an object
        o[key] = o[key] || {};
        return o[key];
      }, obj);  
      
      return lastObj[lastKey];
    },
    marUserEnd(doneKey, userKey) {
      let user = this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname;

      if ( this.getValueByNestedKey( this.cardJson, doneKey ) )
      {
        this.setValueByNestedKey( this.cardJson, userKey, user );
      }
      else
      {
        this.setValueByNestedKey( this.cardJson, userKey, "" ); 
      }
    },
    markUser( doneKey, userKey, dateKey ) {
      let today = new Date();
      let user = this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname;

      if ( this.getValueByNestedKey( this.cardJson, doneKey ) )
      {
        this.setValueByNestedKey( this.cardJson, userKey, user );
        if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
          this.setValueByNestedKey( this.cardJson, dateKey, today );

        if (dateKey.includes('fertilityTest.other'))
          this.setValueByNestedKey( this.cardJson, dateKey, today );
      }
      else
      {
        this.setValueByNestedKey( this.cardJson, userKey, "" ); 
        if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
          this.setValueByNestedKey( this.cardJson, dateKey, "" );

        if (dateKey.includes('fertilityTest.other'))
          this.setValueByNestedKey( this.cardJson, dateKey, "" );
      }

      // if ( !this.getValueByNestedKey(this.cardJson, userKey) || this.getValueByNestedKey(this.cardJson, userKey).trim().length == 0 )
        
      // else
        

      // if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
      // {
      //   if ( !this.getValueByNestedKey(this.cardJson, dateKey))
      //     this.setValueByNestedKey( this.cardJson, dateKey, today );
      //   else
      //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
      // }

      // if (dateKey.includes('fertilityTest.other'))
      // {
      //   if ( !this.getValueByNestedKey(this.cardJson, dateKey) )
      //     this.setValueByNestedKey( this.cardJson, dateKey, today );
      //   else
      //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
      // }
      // if ( event )
      // {
      //   let today = new Date();
      //   let user = this.currentUserPersonalInfo.name +
      //     " " +
      //     this.currentUserPersonalInfo.surname;
      //   this.setValueByNestedKey( this.cardJson, userKey, user );
      //   if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
      //     this.setValueByNestedKey( this.cardJson, dateKey, today );
      // }
      // else
      // {
      //   this.setValueByNestedKey( this.cardJson, userKey, "" );
      //   if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
      //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
      // }
      this.$forceUpdate();
    },
    getVal(item, param) {
      if (!item) return "";

      return getCardParamValue(item, param);
    },
    calculateNawazka(fieldKey, dataPoint) {
      // console.log('a')
      if (
        fieldKey !== "soilStandardAmountGperL" &&
        fieldKey !== "soilWeight" &&
        fieldKey !== "soilPrepedVolume" &&
        dataPoint !== 'prep'
      )
        return;


      let soilStandardAmountGperL = this.cardJson.prep.rows.filter(
        el => el.key == 'soilStandardAmountGperL'
      );

      if ( soilStandardAmountGperL.length === 0 )
        return;

      soilStandardAmountGperL = parseFloat( soilStandardAmountGperL[0].value.trim().replace(',', '.') );

      const soilAmountInLitres = this.cardJson.prep.rows.filter(
        el => el.key == 'soilPrepedVolume'
      )[0].value.trim().replace(',', '.');

      if ( soilAmountInLitres.length == 0 )
        return;

      let weighInGrams = parseFloat( soilAmountInLitres ) * soilStandardAmountGperL;
      // console.log(weighInGrams)
      const k = this.cardJson.prep.rows.findIndex(
        (el) => el.key === "soilWeight"
      );
      this.cardJson.prep.rows[k].value = weighInGrams.toFixed(2);
    },
    calculateExpiryDate( fieldKey, dataPoint ) {
      if ( !["soilPreparationDate", "soilExpiryDays"].includes( fieldKey ) && dataPoint !== 'prep' )
        return;

      const days = this.cardJson.prep.rows.filter( el => el.key === 'soilExpiryDays' )[0].value;
      const d1 = new Date( this.cardJson.prep.rows.filter( el => el.key === 'soilPreparationDate' )[0].value );
      const milliseconds = d1.getTime() + 3600000 * days * 24;
      const d2 = new Date(milliseconds);

      const k = this.cardJson.prep.rows.findIndex( el => el.key === 'soilExpiryDate' );
      this.cardJson.prep.rows[k].value = d2;
    },
    addStrain(rowIndex) {
      this.cardJson.fertilityTest[rowIndex].strains.push({
        strain: "Nie dotyczy",
        method: "Jakoścowa",
        volume: "",
        criteria: "Dobry wzrost",
        reference: "",
        visual: "",
        result: "",
        grade: true,
        date: "21.05.2020",
        user: this.user,
      });
    },
    removeStrain(rowIndex, subRowIndex) {
      this.cardJson.fertilityTest[rowIndex].strains.splice(subRowIndex, 1);
    },
    saveCard() {
      this.$emit("saveCard", this.cardJson);
    },
    markPrepared() {
      this.$emit("savePrepared", this.cardJson);
    },
    addSuplement() {
      this.cardJson.prep.suplements.data.push([
        ...this.cardJson.prep.suplements.data[0].map((el) => {
          return {
            ...el,
            value: "",
          };
        }),
      ]);
    },
    calculateFertilityCheckDate(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        const d1 = new Date(this.cardJson.fertilityTest[row].dateDone);
        const milliseconds = d1.getTime() + 3600000 * newVal;
        const d2 = new Date(milliseconds);
        this.cardJson.fertilityTest[row].date = d2;
      }
    },
    calculateFertilityCheckDate2(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        let time = parseInt(this.cardJson.fertilityTest[row].incubation.time);
        if ( time ) {
          const d1 = new Date(newVal);
          const miliseconds = d1.getTime() + 3600000 * time;
          const d2 = new Date(miliseconds);
          this.cardJson.fertilityTest[row].date = d2;
        }
      }
    },
    setFertilityUserDone(row) {
      let today = new Date();
      if ( this.cardJson.fertilityTest[row].done ) {
        this.cardJson.fertilityTest[row].userDone = this.currentUser;
        this.cardJson.fertilityTest[row].dateDone = today;
      } else {
        this.cardJson.fertilityTest[row].userDone = "";
        this.cardJson.fertilityTest[row].dateDone = "";
      }
    },

    calculateBioCheckDate(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        const d1 = new Date(this.cardJson.bioQuality.rows[row].dateDone);
        const milliseconds = d1.getTime() + 3600000 * newVal;
        const d2 = new Date(milliseconds);
        this.cardJson.bioQuality.rows[row].date = d2;
      }
    },
    calculateBioCheckDate2(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        let time = parseInt(this.cardJson.bioQuality.rows[row].incubation.time);
        if ( time ) {
          const d1 = new Date(newVal);
          const miliseconds = d1.getTime() + 3600000 * time;
          const d2 = new Date(miliseconds);
          this.cardJson.bioQuality.rows[row].date = d2;
        }
      }
    },
    setBioUserDone(row) {
      let today = new Date();
      if (this.cardJson.bioQuality.rows[row].done ) {
        this.cardJson.bioQuality.rows[row].userDone = this.currentUser;
        this.cardJson.bioQuality.rows[row].dateDone = today;
      } else {
        this.cardJson.bioQuality.rows[row].userDone = "";
        this.cardJson.bioQuality.rows[row].dateDone = "";
      }
    },
    setSuplement(index, value) {
      let keys = [
          {
            suplKey: "soilSuplName", soilLocation: "prep", soilKey: "soilName"
          },
          // {
          //   suplKey: "soilSuplSeries", soilLocation: "acceptance", soilKey: "soilSerialNo"
          // },
          {
            suplKey: "soilSuplMark", soilLocation: "acceptance", soilKey: "soilMark"
          },
          {
            suplKey: "soilSuplPrepDate", soilLocation: "prep", soilKey: "soilPrepedDate"
          },
          {
            suplKey: "soilSuplExpiry", soilLocation: "prep", soilKey: "prepedSoilExpiryDate"
          }
        ];

      if ( value == 0 )
      {
        keys.forEach( el => {
          this.cardJson.prep.suplements.data[index][
            this.cardJson.prep.suplements.data[index].findIndex(
              field => field.key == el.suplKey
            )
          ].value = "";
        });
      }
      try {
        let suplement = this
          .soilCards
          .filter(el => el.id == value)[0];

        keys.forEach( el => {
          this.cardJson.prep.suplements.data[index][
            this.cardJson.prep.suplements.data[index].findIndex(
              field => field.key == el.suplKey
            )
          ].value = ( 
            !["prepedSoilExpiryDate", "soilPrepedDate"].includes(el.soilKey) 
            ? getCardParamValue(suplement, el.soilKey) 
            : suplement.meta_data.prep.rows[suplement.meta_data.prep.rows.findIndex(eee => eee.key == el.soilKey)].value.split('T')[0]
          )
        })
      } catch ( e ) {
        // console.error(e)
        return "";
      }
      //this.cardJson.prep.suplements.data
    }
  },
  data() {
    return {
      cardJson: { 
        acceptance: { 
          person: "",
          certified: true,
          satisfies: {
            value: true,
            comment: "",
          },
          rows: [
            {
              label: "Oznaczenie podłoża",
              key: "soilMark",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilMark",
                placeholder: "",
              },
            },
            {
              label: "Data przyjęcia opakowania",
              key: "soilAdmissionDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilAdmissionDate",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Skrót",
              key: "soilShortcut",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilShortcut",
                placeholder: "",
              },
            },
            {
              label: "Nazwa podłoża",
              key: "soilName",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilName",
                placeholder: "",
              },
            },
            {
              label: "Cena netto za opakowanie [zł]",
              key: "soilNetPrice",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilNetPrice",
                placeholder: "",
              },
            },
            {
              label: "Ilość opakowań [szt]",
              key: "soilPackageCount",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilPackageCount",
                placeholder: "",
              },
            },
            {
              label: "Vat [%]",
              key: "soilVatRate",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilVatRate",
                placeholder: "",
              },
            },
            {
              label: "Producent",
              key: "soilManufacturer",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilManufacturer",
                placeholder: "",
              },
            },
            {
              label: "Dostawca",
              key: "soilSuplier",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilSuplier",
                placeholder: "",
              },
            },
            {
              label: "Partia podłoża",
              key: "soilSerialNo",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilSerialNo",
                placeholder: "",
              },
            },
            {
              label: "Data ważności podłoża suchego",
              key: "soilDryExpiry",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilDryExpiry",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            // {
            //   label: "Data przygotowania",
            //   key: "preparationDate",
            //   component: "b-form-input",
            //   value: "Nie dotyczy",
            //   props: {
            //     id: "preparationDate",
            //     placeholder: "np. 20-05-2020",
            //   },
            // },
          ],
        },
        prep: {
          person: "",
          arridity: {
            type: "",
            params: "",
          },
          rows: [
            // {
            //   label: "Oznaczenie podłoża",
            //   key: "soilMark",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilMark",
            //     placeholder: "",
            //   },
            // },
            // {
            //   label: "Skrót",
            //   key: "soilShortcut",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilShortcut",
            //     placeholder: "",
            //   },
            // },
            // {
            //   label: "Nazwa podłoża",
            //   key: "soilName",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilName",
            //     placeholder: "",
            //   },
            // },
            {
              label: "Data otwarcia opakowania",
              key: "soilPackageOpenedDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilPackageOpenedDate",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Data przygotowania podłoża",
              key: "soilPreparationDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilPreparationDate",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Ważność podłoża [ w dniach ]",
              key: "soilExpiryDays",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilExpiryDays",
                type: "number"
              }
            },
            {
              label: "Data ważności sporządzonego podłoża",
              key: "soilExpiryDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilExpiryDate",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Naważka [ g ] ",
              key: "soilWeight",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilWeight",
                placeholder: "",
              },
            },
            {
              label: "Sporządzona objętość [ L ]",
              key: "soilPrepedVolume",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilPrepedVolume",
                placeholder: "",
              },
            },
            {
              label: "standardowa naważka w gramach na litr",
              key: "soilStandardAmountGperL",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilStandardAmountGperL",
                placeholder: "",
              },
            },
            {
              label: "Dozowanie [ ml ]",
              key: "soilDosage",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilDosage",
                placeholder: "",
              },
            },
            {
              label: "Odczyt z dejonizatora",
              key: "soilDeionizer",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilDeionizer",
                placeholder: "",
              },
            },
          ],
          suplements: {
            added: true,
            data: [
              [
                {
                  label: "Nazwa suplementu/barwnika",
                  key: "soilSuplName",
                  component: "b-form-input",
                  value: "",
                  props: {
                    id: "soilSuplName",
                    placeholder: "",
                  },
                },
                {
                  label: "Partia suplementu",
                  key: "soilSuplSeries",
                  component: "v-select",
                  value: "",
                  props: {
                    id: 'soilSuplSeries'
                  }
                },
                {
                  label: "Oznaczenie suplementu/barwnika",
                  key: "soilSuplMark",
                  component: "b-form-input",
                  value: "",
                  props: {
                    id: "soilSuplMark",
                    placeholder: "",
                  },
                },
                {
                  label: "Data sporządzenia suplementu/barwnika*",
                  key: "soilSuplPrepDate",
                  component: "b-form-input",
                  value: "",
                  props: {
                    id: "soilSuplPrepDate",
                    placeholder: "",
                  },
                },
                {
                  label: "Data ważności suplementu/barwnika ",
                  key: "soilSuplExpiry",
                  component: "b-form-input",
                  value: "",
                  props: {
                    id: "soilSuplExpiry",
                    placeholder: "",
                  },
                },
                {
                  label: "Ilość dodawanego suplementu",
                  key: "soilSuplAmount",
                  component: "b-form-input",
                  value: "",
                  props: {
                    id: "soilSuplAmount",
                    placeholder: "",
                  },
                },
                {
                  label:
                    "Okres wazności podłoża po dodaniu suplementu [ w dniach ]",
                  key: "soilExpiryAfterSupl",
                  component: "b-form-input",
                  value: "",
                  props: {
                    id: "soilExpiryAfterSupl",
                    placeholder: "",
                  },
                },
              ],
            ],
          },
        },
        chemTest: {
          date: "20-03-2019",
          user: "",
          rows: [
            {
              parameter: "Wartość pH podłoża",
              expected: "",
              achieved: false,
              key: "ph",
            },
            {
              parameter: "Kolor podłoża",
              expected: "",
              achieved: false,
              key: "color",
            },
            {
              parameter: "Klarowność",
              expected: "",
              achieved: false,
              key: "clarity",
            },
            {
              parameter: "Obecność strątów",
              expected: "",
              achieved: false,
              key: "precipitates",
            },
            {
              parameter: "Jednorodność",
              expected: "",
              achieved: false,
              key: "homogeneity",
            },
            {
              parameter: "Konsystencja",
              expected: "",
              achieved: false,
              key: "consistency",
            },
            {
              parameter: "Grubość warstwy[mm]",
              expected: "",
              achieved: false,
              key: "size",
            },
            {
              parameter: "Objętość [ml]",
              expected: "",
              achieved: false,
              key: "volume",
            },
            {
              parameter: "Inne uwagi",
              expected: "Brak",
              achieved: false,
              key: "other",
            },
          ],
        },
        bioQuality: {
          allowed: true,
          date: "12-12-2021",
          person: "",
          general: [
            {
              label: "Data przygotowania podłoża",
              key: "soilPreparedDate",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilPreparedDate",
                placeholder: "np. -",
              },
            },
            {
              label: "Data ważności podłoża po przygotowaniu",
              key: "soilExpirydDateAfterPrep",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilExpirydDateAfterPrep",
                placeholder: "np. -",
              },
            },
            {
              label: "Data rozpuszczenia podłoża",
              key: "soilDissolutionDate",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilDissolutionDate",
                placeholder: "np. -",
              },
            },
            {
              label: "Data ważności podłoża po rozlaniu na płytki",
              key: "soilExpiryDateAfterTest",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilExpiryDateAfterTest",
                placeholder: "np. -",
              },
            },
          ],
          rows: {
            aridity: {
              tested: "Jałowość",
              incubation: {
                temp: "",
                time: "",
              },
              date: "21.05.2022",
              user: "",
              testedAmount: "",
              contamination: "",
              visualGrade: "",
              grade: true,
              done: false,
              dateDone: "",
              userDone: "",
            },
          },
        },
        fertilityTest: {
          fertility: {
            dateStarted: "",
            dateDone: "",
            tested: "Żyzność",
            incubation: {
              temp: "",
              time: "",
            },
            strains: [
              {
                strain: "Nie dotyczy",
                method: "Jakoścowa",
                volume: "",
                criteria: "Dobry wzrost",
                reference: "",
                visual: "",
                result: "",
                grade: true,
                date: "21.05.2020",
                user: this.user,
              },
            ],
          },
          selectivity: {
            dateStarted: "",
            dateDone: "",
            tested: "Selektywność",
            incubation: {
              temp: "",
              time: "",
            },
            strains: [
              {
                strain: "Nie dotyczy",
                method: "Jakoścowa",
                volume: "",
                criteria: "Dobry wzrost",
                reference: "",
                visual: "",
                result: "",
                grade: true,
                date: "21.05.2020",
                user: this.user,
              },
            ],
          },
          specificity: {
            dateStarted: "",
            dateDone: "",
            tested: "Specyficzność",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: this.user,
            strains: [
              {
                strain: "Nie dotyczy",
                method: "Jakoścowa",
                volume: "",
                criteria: "Dobry wzrost",
                reference: "",
                visual: "",
                result: "",
                grade: true,
                date: "21.05.2020",
                user: this.user,
              },
            ],
          },
          other: {
            tested: "Inne uwagi",
            value: "",
            date: "21.05.2020",
            user: this.user,
            allowed: false,
          },
        },
        additional: {
          allowedForUsage: true,
          userAllowed: this.user,
          dateAllowed: "",
          whoAllowed: "",
        },
      },
    };
  },
  watch: {
    "cardJson.fertilityTest.aridity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("aridity", newVal, oldVal);

    },
    "cardJson.fertilityTest.fertility.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("fertility", newVal, oldVal);
    },
    "cardJson.fertilityTest.selectivity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("selectivity", newVal, oldVal);
    },
    "cardJson.fertilityTest.specificity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("specificity", newVal, oldVal);
    },

    "cardJson.fertilityTest.aridity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("aridity", newVal, oldVal);

    },
    "cardJson.fertilityTest.fertility.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("fertility", newVal, oldVal);
    },
    "cardJson.fertilityTest.selectivity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("selectivity", newVal, oldVal);
    },
    "cardJson.fertilityTest.specificity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("specificity", newVal, oldVal);
    },

    "cardJson.bioQuality.rows.aridity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateBioCheckDate("aridity", newVal, oldVal);
      this.cardJson.additional.dateAllowed =
          this.cardJson.bioQuality.rows["aridity"].date;
    },

    "cardJson.bioQuality.rows.aridity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateBioCheckDate2("aridity", newVal, oldVal);
      this.cardJson.additional.dateAllowed =
          this.cardJson.bioQuality.rows["aridity"].date;
    },

    "cardJson.fertilityTest.aridity.done": function () {
      this.setFertilityUserDone("aridity");
    },
    "cardJson.fertilityTest.fertility.done": function () {
      this.setFertilityUserDone("fertility");
    },
    "cardJson.fertilityTest.selectivity.done": function () {
      this.setFertilityUserDone("selectivity");
    },
    "cardJson.fertilityTest.specificity.done": function () {
      this.setFertilityUserDone("specificity");
    },
    "cardJson.bioQuality.rows.aridity.done": function () {
      this.setBioUserDone("aridity");
    },
    soilCardsAll: {
      handler() {
        let candidate = null;
        
        candidate = this.soilCardsAll.filter( el => el.id == this.$route.params.cardId )[0]

        if (!candidate) return;

        checkObject( candidate.meta_data, this.cardJson );

        const { acceptance, prep, chemTest, bioQuality, fertilityTest, additional } =
          candidate.meta_data;

        if ( prep.rows.filter( el => el.key == 'soilWeight' ).length == 0 )
          prep.rows.push({label: "Naważka [ g ] ",
              key: "soilWeight",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilWeight",
                placeholder: "",
              },
            });

        if ( prep.rows.filter( el => el.key == 'soilPrepedVolume' ).length == 0 )
          prep.rows.push({
              label: "Sporządzona objętość [ L ]",
              key: "soilPrepedVolume",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilPrepedVolume",
                placeholder: "",
              },
            })

        if ( prep.rows.filter( el => el.key == 'soilStandardAmountGperL' ).length == 0 )
          prep.rows.push({
              label: "standardowa naważka w gramach na litr",
              key: "soilStandardAmountGperL",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilStandardAmountGperL",
                placeholder: "",
              },
            })


        if (candidate)
          this.cardJson = {
            acceptance,
            prep,
            chemTest,
            bioQuality,
            fertilityTest,
            additional,
          };
      },
      immediate: true,
    },
  },
  computed: {
    currentUser() {
      return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
    },
    ...mapGetters(["soilCards", "soilCardsAll", "currentUserPersonalInfo", "suplements"]),
    suplementInputs() {
      // prep.suplements.data
      const referenceArray = Object.values(this.cardJson.prep.suplements.data.map(supl => supl.filter( el => el.key == 'soilSuplSeries' )[0].value));
      
      return [
        {
          value: 0,
          text: ""
        },
        ...this.soilCardsAll
        .filter( el => ( el.level == 3 && el.type == 'test' && el.status == 'in-use' ) || referenceArray.includes(el.id) )
        .map( el => {
          return {
             text: this.getVal(el, "soilMark") + " / " + this.getVal(el, "soilSerialNo") + " / " + (new Date( Date.parse(this.getVal(el, "soilDryExpiry")) )).toLocaleDateString('pl-PL'),
            value: el.id
          }
        })
      ];
    },
    cardsInputValues() {
      // this.cardJson.fertilityTest . strains. reference
      const referenceArray = Object.values(this.cardJson.fertilityTest).flatMap(subObj =>
        subObj.strains ? subObj.strains.map(strain => strain.reference) : []
      );
      return [ 
        {
          value: "Nie dotyczy",
          text: "Nie dotyczy",
        },
        {
          value: "null",
          text: ""
        },
        ...this.soilCardsAll
          // .filter((el) => el.meta_data.general)
          // .filter((el) => el.meta_data.acceptance)
          .filter((el) => ( el.status === "in-use" && el.level == 3 ) || referenceArray.includes( el.id ) ) // && el.type === "ready"
          .map((el) => {
            return {
              value: el.id,
              text: this.getVal(el, "soilMark") + "/" + this.getVal(el, "soilSerialNo") + "/" + (new Date( Date.parse(this.getVal(el, "soilDryExpiry")) )).toLocaleDateString('pl-PL')
            };
          }),
      ];
    },
    user() {
      if (this.currentUserPersonalInfo.name && !this.$route.params.cardId)
        return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
      else if (this.$route.params.cardId)
        return this.soilCardsAll.filter(
          (el) => el.id == this.$route.params.cardId
        )[0]
          ? this.soilCardsAll.filter((el) => el.id == this.$route.params.cardId)[0]
              .user.firstName +
              " " +
              this.soilCardsAll.filter(
                (el) => el.id == this.$route.params.cardId
              )[0].user.lastName
          : "";

      return "";
    },
  },
  mounted() {
    this.$store.dispatch("soilCardsList");
    this.$store.dispatch("suplementList")

    // if (!this.$route.params.cardId) {
      // let today = new Date();
      // this.cardJson.chemTest.date = today;
      // this.cardJson.chemTest.user = this.user;

      // for (let key in this.cardJson.fertilityTest) {
      //   this.cardJson.fertilityTest[key].date = today;
      //   this.cardJson.fertilityTest[key].dateDone = today;
      //   // this.cardJson.fertilityTest[key].userDone = this.user;
      //   // this.cardJson.fertilityTest[key].user = this.user;
      // }

      // for (let key in this.cardJson.bioQuality.rows) {
      //   this.cardJson.bioQuality.rows[key].date = today;
      //   this.cardJson.bioQuality.rows[key].dateDone = today;
      // }

      // this.cardJson.additional.userAllowed = this.user;
      // this.cardJson.additional.whoAllowed = this.user;
      // this.cardJson.acceptance.person = this.user;
      // this.cardJson.prep.person = this.user;
    // }
  },
};
</script>

<style lang="scss" scoped>
.colVerticalCenter {
  display: grid;
  align-items: center;
}
table th,
table td {
  padding: 5px 10px;
}

table tr {
  border-bottom: 1px solid #000;
}
table td {
  border-right: 1px solid #000;
}
table tr td:last-of-type {
  border-right: none;
}

.dataTable {
  header {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;

    div {
      padding: 5px 0;
      font-weight: bold;
    }
  }
  header,
  .tableBody {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 10px;

    .tableRow {
      border-bottom: 1px solid #000;
      padding: 5px 0 20px;
      grid-column: 1 / span 8;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      align-items: center;

      &:last-of-type {
        border-bottom: none;
      }

      .tableSubRow {
        grid-column: 3 / span 5;
        display: grid;
        grid-template-columns: 30px repeat(5, 1fr);
        grid-column-gap: 10px;
      }
    }
  }
}

.span6 {
  grid-column: 2 / span 6;
}

.addSubRow {
}
</style>
