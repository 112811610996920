var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxl-12 mb-4"},[_c('b-button',{staticClass:"mt-3 mb-5",attrs:{"variant":"warning"},on:{"click":_vm.goBack}},[_vm._v("Anuluj / Wstecz")]),_c('h2',{staticClass:"mb-6"},[_vm._v("Typ pożywki")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2"},[_c('b-form-select',{attrs:{"disabled":this.$route.params.cardId !== undefined,"options":[
              {
                value: 'ready',
                text: 'Pożywka gotowa',
              },
              {
                value: 'test',
                text: 'Testy i suplementy',
              },
              {
                value: 'dry',
                text: 'Pożywka Sucha',
              } ]},model:{value:(_vm.cardJson.type),callback:function ($$v) {_vm.$set(_vm.cardJson, "type", $$v)},expression:"cardJson.type"}})],1)])],1),_c('div',{staticClass:"col-xxl-8"},[_c('h2',{staticClass:"mb-6"},[_vm._v("Dane ogólne:")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3"},[_c('b-form-group',{attrs:{"label":"Tytuł dokumentu","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title","placeholder":"np. Karta dopuszczenia pożywki"},model:{value:(_vm.cardJson.name),callback:function ($$v) {_vm.$set(_vm.cardJson, "name", $$v)},expression:"cardJson.name"}})],1)],1),_c('div',{staticClass:"col-xl-3"},[_c('b-form-group',{attrs:{"label":"Kod dokumentu","label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","placeholder":"np. IT-05.05"},model:{value:(_vm.cardJson.code),callback:function ($$v) {_vm.$set(_vm.cardJson, "code", $$v)},expression:"cardJson.code"}})],1)],1),_c('div',{staticClass:"col-xl-3"},[_c('b-form-group',{attrs:{"label":"Status pożywki","label-for":"docStage"}},[_c('b-form-select',{attrs:{"options":[
                {
                  value: 'new',
                  text: 'Nowa'
                },
                {
                  value: 'in-use',
                  text: 'W użyciu',
                },
                {
                  value: 'finished',
                  text: 'Zakończone',
                } ]},model:{value:(_vm.cardJson.status),callback:function ($$v) {_vm.$set(_vm.cardJson, "status", $$v)},expression:"cardJson.status"}})],1)],1)])]),_c('div',{staticClass:"col-xxl-12"},[_c(_vm.forms[_vm.cardJson.type],{tag:"component",on:{"saveCard":_vm.saveCard,"savePrepared":_vm.markPrepared}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }