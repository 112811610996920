<template>
  <div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div
            class="col-xl-3"
            v-for="(field, index) in this.cardJson.general.filter((el) =>
            ['soilMark', 'soilShortcode', 'soilName'].includes(el.key)
          )"
            v-bind:key="'some' + index"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component
                v-bind:is="field.component"
                v-bind="field.props" 
                v-model="field.value"
            ></component>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">Protokół przyjęcia pożywki gotowej:</h2>
      <div class="row">
        <div class="col-xl-12">
<!--          <p class="mb-1"><b>Osoba sprawdzająca podłoże: </b> b-fo</p>-->
          <div class="row">
            <div class="col-xl-3">
              <b-form-checkbox 
                v-model="cardJson.acceptanceProtocol.accepted"
                @change="markUser('acceptanceProtocol.accepted', 'acceptanceProtocol.person', 'acceptanceProtocol.date' )"
              >Przyjęto</b-form-checkbox>
              <b-form-group label="Osoba sprawdzająca podłoże">
                <b-form-input v-model="cardJson.acceptanceProtocol.person" />
              </b-form-group>
              <p>
                <b>Data: </b>
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="acceptanceProtocolDate"
                  v-model="cardJson.acceptanceProtocol.date"
                >
                </b-form-datepicker>
              </p>
            </div>
          </div>
          
          <div class="row">
            <div
              v-for="(field, index) in this.cardJson.general.filter( f => f.key != 'preparationDate' ).filter(
                (el) => !['soilMark', 'soilShortcode', 'soilName'].includes(el.key)
              )"
              v-bind:key="index"
              class="col-xl-3"
            >
              <b-form-group :label="field.label" :label-for="field.key">
                <component
                  v-bind:is="field.component"
                  v-bind="field.props"
                  v-model="field.value"
                ></component>
              </b-form-group>
            </div>
            <div class="col-xl-3">
              <b-form-group label="Certyfikat">
                <b-form-radio
                  v-model="cardJson.acceptanceProtocol.certificate"
                  name="certificate"
                  value="true"
                  >Tak</b-form-radio
                >
                <b-form-radio
                  v-model="cardJson.acceptanceProtocol.certificate"
                  name="certificate"
                  value="false"
                  >Nie</b-form-radio
                >
              </b-form-group>
            </div>
            <div class="col-xl-3">
              <b-form-group label="Ocena opakowania">
                <b-form-radio
                  v-model="cardJson.acceptanceProtocol.grade"
                  name="grade"
                  value="true"
                  >Spełnia oczekiwania</b-form-radio
                >
                <b-form-radio
                  v-model="cardJson.acceptanceProtocol.grade"
                  name="grade"
                  value="false"
                  >Nie spełnia oczekiwań</b-form-radio
                >
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">
        Badane właściwości fizyko-chemiczne pożywki gotowej:
      </h2>
      <div class="row">
        <div class="col-xxl-8">
          <div class="row">
            <div class="col-xxl-12">
<!--              <p class="mb-1"><b>Osoba sprawdzająca podłoże: </b>{{ user }}</p>-->
              <b-form-checkbox 
                v-model="cardJson.chemTest.accepted"
                @change="markUser('chemTest.accepted', 'chemTest.person', 'chemTest.date' )"
              >Sprawdzono</b-form-checkbox>
              <b-form-group label="Osoba sprawdzająca podłoże">
                <b-form-input v-model="this.cardJson.chemTest.person" />
              </b-form-group>
              <p>
                <b>Data: </b>
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="chemtestDate"
                  v-model="cardJson.chemTest.date"
                >
                </b-form-datepicker>
              </p>

              <div class="row">
                <div class="col-xxl-4">
                  <b>Parametry badane:</b>
                </div>
                <div class="col-xxl-4">
                  <b>Parametry oczekiwane:</b>
                </div>
                <div class="col-xxl-4">
                  <b>Parametry uzyskane:</b>
                </div>
              </div>

              <div
                v-for="(row, index) in this.cardJson.chemTest.rows"
                class="row"
                v-bind:key="index"
              >
                <div class="col-xxl-4 colVerticalCenter">
                  {{ row.parameter }}
                </div>
                <div class="col-xxl-4 colVerticalCenter">
                  <b-form-input v-model="row.expected"></b-form-input>
                </div>
                <div class="col-xxl-4 colVerticalCenter">
                  <b-form-group>
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="true"
                      >Tak</b-form-radio
                    >
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="false"
                      >Nie</b-form-radio
                    >
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="n/a"
                      >Nie dotyczy</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-xxl-4 colVerticalCenter">
                  Spełnia oczekiwania:
                </div>
                <div class="col-xxl-8 colVerticalCenter">
                  <b-form-group>
                    <b-form-radio
                      v-model="cardJson.chemTest.allowed"
                      name="allowed"
                      value="true"
                      >Tak</b-form-radio
                    >
                    <b-form-radio
                      v-model="cardJson.chemTest.allowed"
                      name="allowed"
                      value="false"
                      >Nie</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">
        Protokół żyzności, selektywnośći, specyfizcności pożywki:
      </h2>

      <section class="dataTable">
        <header>
          <div>Parametry badane</div>
          <div>Parametry inkubacji</div>
          <div>Szczep testowy użyty do badania</div>
          <div>Ocena wizualna</div>
          <div>Pożywka referencyjna</div>
          <div>Współczynnik żyzności <b>P</b><sub>R</sub></div>
          <div>Spełnia oczekiwania / nie spełnia oczekiwań</div>
          <div>Data / sprawdził</div>
        </header>
        <section class="tableBody">
          <div
            v-for="(row, index) in this.cardJson.fertilityTest"
            v-bind:key="'fertility' + index"
            class="tableRow"
          >
            <div>
              {{ row.tested }}
              <b-form-group label="" v-if="index !== 'other'">
                <b-form-checkbox v-model="row.done">Wykonano</b-form-checkbox>
                <b-form-datepicker
                  v-model="row.dateDone"
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
                <b-form-input placeholder="Wykonał" v-model="row.userDone" />
              </b-form-group>
            </div>
            <template v-if="index !== 'other'">
              <div>
                <b-form-group label="Temp. [℃]">
                  <b-form-input placeholder="" v-model="row.incubation.temp" />
                </b-form-group>
                <b-form-group label="Czas [h]">
                  <b-form-input
                    type="number"
                    label="Czas"
                    placeholder=""
                    v-model="row.incubation.time"
                  />
                </b-form-group>
              </div>
              <section
                class="tableSubRow"
                v-for="(subRow, subIndex) in row.strains"
                v-bind:key="'sub' + subIndex"
              >
                <div>
                  <a
                    class="btn btn-icon btn-light btn-sm addSubRow"
                    @click="addStrain(index)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Code/Plus.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-light btn-sm removeSubRow"
                    v-if="row.strains.length > 1"
                    @click="removeStrain(index, subIndex)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </div>
                <div>
                  <b-form-textarea
                    placeholder="np. Listeria monocytogenes 4b WDCM 00021"
                    v-model="subRow.strain"
                    rows="1"
                  />
                </div>
                <div>
                  <b-form-textarea
                    rows="1"
                    placeholder="np. Brak wzrostu"
                    v-model="subRow.visual"
                  />
                </div>
                <div>
                  <!--                  <b-form-input-->
                  <!--                    placeholder="np. Nie dotyczy"-->
                  <!--                    v-model="subRow.reference"-->
                  <!--                  />-->
                  <b-form-select
                    v-model="subRow.reference"
                    :options="cardsInputValues"
                  />
                </div>
                <div>
                  <b-form-textarea
                    rows="1"
                    placeholder="np. Nie dotyczy"
                    v-model="subRow.fertility"
                  />
                </div>
                <div>
                  <b-form-select
                    :options="[
                      {
                        value: true,
                        text: 'Spełnia oczekiwania',
                      },
                      {
                        value: false,
                        text: 'Nie spełnia oczekiwań',
                      },
                    ]"
                    v-model="subRow.grade"
                  />
                </div>
              </section>
              <div>
                <b-form-checkbox 
                  v-model="row.checked"
                  @change="markUser( 'fertilityTest.' + index + '.checked','fertilityTest.' + index + '.user', 'fertilityTest.' + index + '.date' )"
                >Sprawdzono</b-form-checkbox>
                <b-form-input v-model="row.user" />
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :id="index + 'specificDate'"
                  v-model="row.date"
                >
                </b-form-datepicker>
              </div>
            </template>
            <template v-if="index === 'other'">
              <div class="span6">
                <b-form-textarea v-model="row.value" />
              </div>
              <div>
                <b-form-checkbox 
                  v-model="row.checked"
                  @change="markUser( 'fertilityTest.' + index + '.checked', 'fertilityTest.' + index + '.user', 'fertilityTest.' + index + '.date' )"
                >Wprowadzono</b-form-checkbox>
                <b-form-input v-model="row.user" />
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="otherspecificDate"
                  v-model="row.date"
                >
                </b-form-datepicker>
              </div>
            </template>
          </div>
        </section>
      </section>

      <div class="row">
        <div class="col-xl-3">
          <b-form-group label="Dopuszczono do użytku">
            <b-form-radio
              v-model="cardJson.additional.allowedForUsage"
              name="allowedForUsage"
              value="true"
              >Tak</b-form-radio
            >
            <b-form-radio
              v-model="cardJson.additional.allowedForUsage"
              name="allowedForUsage"
              value="false"
              >Nie</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Data dopuszczenia">
            <b-form-datepicker 
              v-model="cardJson.additional.dateAllowed" 
              reset-button
              type="date"
              placeholder="dd.mm.rrrr"
              size="xs"
              :start-weekday="1"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
            />
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Dopuścił">
            <b-form-checkbox
              v-model="cardJson.additional.allowedChecked"
              @change="marUserEnd('additional.allowedChecked', 'additional.userAllowed')"
            >Dopuść</b-form-checkbox>
            <b-form-input v-model="cardJson.additional.userAllowed" />
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Zatwierdził">
            <b-form-checkbox
              v-model="cardJson.additional.acceptedChecked"
              @change="marUserEnd('additional.acceptedChecked', 'additional.whoAllowed')"
            >Zatwierdź</b-form-checkbox>
            <b-form-input v-model="cardJson.additional.whoAllowed" />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <b-button @click="saveCard" variant="primary" class="mt-3"
        >Zapisz pożywkę</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCardParamValue } from "../../../core/services/store/soil.module";
import checkObject from '@/core/services/checkObject'

export default {
  name: "ReadySoilForm",
  emits: ["saveCard", "savePrepared"],
  methods: {
    setValueByNestedKey(obj, nestedKey, value) {
      const keys = nestedKey.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, key) => {
        // Check if the key is a numerical index and whether it points to an array
        if (!isNaN(key) && (Array.isArray(o))) {
          // Ensure the array is large enough
          key = parseInt(key, 10);
          while (key >= o.length) {
            o.push({});
          }
          return o[key];
        }
        // If not, proceed with an object
        o[key] = o[key] || {};
        return o[key];
      }, obj);  
      lastObj[lastKey] = value;
      this.$forceUpdate();
    },
    getValueByNestedKey(obj, nestedKey) {
      const keys = nestedKey.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, key) => {
        // Check if the key is a numerical index and whether it points to an array
        if (!isNaN(key) && (Array.isArray(o))) {
          // Ensure the array is large enough
          key = parseInt(key, 10);
          while (key >= o.length) {
            o.push({});
          }
          return o[key];
        }
        // If not, proceed with an object
        o[key] = o[key] || {};
        return o[key];
      }, obj);  
      
      return lastObj[lastKey];
    },
    marUserEnd(doneKey, userKey) {
      let user = this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname;

      if ( this.getValueByNestedKey( this.cardJson, doneKey ) )
      {
        this.setValueByNestedKey( this.cardJson, userKey, user );
      }
      else
      {
        this.setValueByNestedKey( this.cardJson, userKey, "" ); 
      }
    },
    markUser( doneKey, userKey, dateKey ) {
      let today = new Date();
      let user = this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname;

      if ( this.getValueByNestedKey( this.cardJson, doneKey ) )
      {
        this.setValueByNestedKey( this.cardJson, userKey, user );
        if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
          this.setValueByNestedKey( this.cardJson, dateKey, today );

        if (dateKey.includes('fertilityTest.other'))
          this.setValueByNestedKey( this.cardJson, dateKey, today );
      }
      else
      {
        this.setValueByNestedKey( this.cardJson, userKey, "" ); 
        if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
          this.setValueByNestedKey( this.cardJson, dateKey, "" );

        if (dateKey.includes('fertilityTest.other'))
          this.setValueByNestedKey( this.cardJson, dateKey, "" );
      }
      
        // let today = new Date();
        // let user = this.currentUserPersonalInfo.name +
        //   " " +
        //   this.currentUserPersonalInfo.surname;

        // if ( !this.getValueByNestedKey(this.cardJson, userKey) || this.getValueByNestedKey(this.cardJson, userKey).trim().length == 0 )
        //   this.setValueByNestedKey( this.cardJson, userKey, user );
        // else
        //   this.setValueByNestedKey( this.cardJson, userKey, "" ); 

        // if ( !dateKey.includes('bioQuality') && !dateKey.includes('fertilityTest') )
        // {
        //   if ( !this.getValueByNestedKey(this.cardJson, dateKey))
        //     this.setValueByNestedKey( this.cardJson, dateKey, today );
        //   else
        //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
        // }

        // if (dateKey.includes('fertilityTest.other'))
        // {
        //   if ( !this.getValueByNestedKey(this.cardJson, dateKey) )
        //     this.setValueByNestedKey( this.cardJson, dateKey, today );
        //   else
        //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
        // }
      
    },
    getVal(item, param) {
      if (!item) return "";

      return getCardParamValue(item, param);
    },
    addStrain(rowIndex) {
      this.cardJson.fertilityTest[rowIndex].strains.push({
        strain: "Nie dotyczy",
        visual: "brak wzrostu",
        reference: "Nie dotyczy",
        fertility: "Nie dotyczy",
        grade: true,
      });
    },
    removeStrain(rowIndex, subRowIndex) {
      this.cardJson.fertilityTest[rowIndex].strains.splice(subRowIndex, 1);
    },
    saveCard() {
      this.$emit("saveCard", this.cardJson);
    },
    markPrepared() {
      this.$emit("savePrepared", this.cardJson);
    },
    calculateFertilityCheckDate(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        const d1 = new Date(this.cardJson.fertilityTest[row].dateDone);
        const milliseconds = d1.getTime() + 3600000 * newVal;
        const d2 = new Date(milliseconds);
        this.cardJson.fertilityTest[row].date = d2;
      }
    },
    calculateFertilityCheckDate2(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        let time = parseInt(this.cardJson.fertilityTest[row].incubation.time);
        if ( time ) {
          const d1 = new Date(newVal);
          const miliseconds = d1.getTime() + 3600000 * time;
          const d2 = new Date(miliseconds);
          this.cardJson.fertilityTest[row].date = d2;
        }
      }
    },
    setFertilityUserDone(row) {
      let today = new Date();
      if (this.cardJson.fertilityTest[row].done ) {
        this.cardJson.fertilityTest[row].userDone = this.currentUser;
        this.cardJson.fertilityTest[row].dateDone = today;
        // this.cardJson.fertilityTest[row].user = this.user;
      } else {
        this.cardJson.fertilityTest[row].userDone = "";
        this.cardJson.fertilityTest[row].dateDone = "";
      }
    },
  },
  data() {
    return {
      cardJson: {
        general: [
          {
            label: "Oznaczenie podłoża",
            key: "soilMark",
            component: "b-form-input",
            value: "",
            props: {
              id: "soilMark",
              placeholder: "",
            },
          },
          {
            label: "Skrót",
            key: "soilShortcode",
            component: "b-form-input",
            value: "",
            props: {
              id: "soilShortcode",
              placeholder: "",
            },
          },
          {
            label: "Nazwa podłoża",
            key: "soilName",
            component: "b-form-input",
            value: "",
            props: {
              id: "soilName",
              placeholder: "",
            },
          },
          {
            label: "Partia / LOT",
            key: "soilSerialNo",
            component: "b-form-input",
            value: "",
            props: {
              id: "soilLOT",
              placeholder: "",
            },
          },

          {
            label: "Data przygotowania",
            key: "preparationDate",
            component: "b-form-input",
            value: "",
            props: {
              id: "preparationDate",
              placeholder: "np. 20-05-2020",
            },
          },
          // {
          //   label: "Sporządzona objętość oraz dozowanie",
          //   key: "volumeAndDosage",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "volumeAndDosage",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          // {
          //   label: "Nazwa suplementu/barwnika",
          //   key: "suplementName",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "suplementName",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          // {
          //   label: "Sterylizacja (autoklaw/cykl)",
          //   key: "sterilization",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "sterilization",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          {
            label: "Producent",
            key: "serial",
            component: "b-form-input",
            value: "",
            props: {
              id: "serial",
              placeholder: "",
            },
          },
          {
            label: "Dostawca",
            key: "suplier",
            component: "b-form-input",
            value: "",
            props: {
              id: "suplier",
              placeholder: "",
            },
          },
          {
            label: "Data przyjęcia opakowania",
            key: "datePackageAdmitted",
            component: "b-form-datepicker",
            value: "",
            props: {
              id: "datePackageAdmitted",
              placeholder: "",
              "reset-button": true,
              type: "date",
              size: "xs",
              "start-weekday": "1",
              "date-format-options": {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }
            },
          },
          {
            label: "Cena netto za opakowanie [zł]",
            key: "packageNetPrice",
            component: "b-form-input",
            value: "",
            props: {
              id: "packageNetPrice",
              placeholder: "",
            },
          },
          {
            label: "Ilość opakowań [szt]",
            key: "packagesAmount",
            component: "b-form-input",
            value: "",
            props: {
              id: "packagesAmount",
              placeholder: "",
            },
          },
          {
            label: "Vat [%]",
            key: "vatRate",
            component: "b-form-input",
            value: "",
            props: {
              id: "vatRate",
              placeholder: "",
            },
          },
          // {
          //   label: "Data otwarcia opakowania",
          //   key: "dateOpened",
          //   component: "b-form-input",
          //   value: "",
          //   props: {
          //     id: "dateOpened",
          //     placeholder: "np. 15.12.2022",
          //   },
          // },
          {
            label: "Data ważności",
            key: "soilDryExpiry",
            component: "b-form-datepicker",
            value: "",
            props: {
              id: "expiryDate",
              placeholder: "",
              "reset-button": true,
              type: "date",
              size: "xs",
              "start-weekday": "1",
              "date-format-options": {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }
            },
          },
          // {
          //   label: "Naważka",
          //   key: "weight",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "weight",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          // {
          //   label: "Data ważności (naważki)",
          //   key: "weightExpiryDate",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "weightExpiryDate",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          // {
          //   label: "Ilość dodana (naważki)",
          //   key: "weightAdded",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "weightAdded",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          // {
          //   label: "Parametry sterylizacji",
          //   key: "sterilizationParams",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "sterilizationParams",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
          // {
          //   label: "Odczyt z jonizatora",
          //   key: "ionizerReading",
          //   component: "b-form-input",
          //   value: "Nie dotyczy",
          //   props: {
          //     id: "ionizerReading",
          //     placeholder: "np. Nie dotyczy",
          //   },
          // },
        ],
        acceptanceProtocol: {
          date: "",
          certificate: true,
          grade: true,
          person: ""
        },
        chemTest: {
          date: "20-03-2019",
          allowed: true,
          person: "",
          rows: [
            {
              parameter: "Kolor podłoża",
              expected: "",
              achieved: true,
              key: "color",
            },
            {
              parameter: "Klarowność",
              expected: "",
              achieved: false,
              key: "clarity",
            },
            {
              parameter: "Obecność strątów",
              expected: "",
              achieved: false,
              key: "precipitates",
            },
            {
              parameter: "Jednorodność",
              expected: "",
              achieved: false,
              key: "homogeneity",
            },
            {
              parameter: "Konsystencja",
              expected: "",
              achieved: false,
              key: "consistency",
            },
            {
              parameter: "Grubość warstwy[mm] / objętość[ml]",
              expected: "",
              achieved: false,
              key: "size",
            },
            {
              parameter: "Inne uwagi",
              expected: "Brak Uwag",
              achieved: false,
              key: "other",
            },
          ],
        },
        fertilityTest: {
          aridity: {
            tested: "Jałowość",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: "",
            strains: [
              {
                strain: "Nie dotyczy",
                visual: "brak wzrostu",
                reference: "Nie dotyczy",
                fertility: "Nie dotyczy",
                grade: true,
              },
            ],
            done: false,
            dateDone: "",
            userDone: "",
            checked: false
          },
          fertility: {
            tested: "Żyzność",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: this.user,
            strains: [
              {
                strain: "Nie dotyczy",
                visual: "brak wzrostu",
                reference: "Nie dotyczy",
                fertility: "Nie dotyczy",
                grade: true,
              },
            ],
            done: false,
            dateDone: "",
            userDone: "",
            checked: false
          },
          selectivity: {
            tested: "Selektywność",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: this.user,
            strains: [
              {
                strain: "Nie dotyczy",
                visual: "brak wzrostu",
                reference: "Nie dotyczy",
                fertility: "Nie dotyczy",
                grade: true,
              },
            ],
            done: false,
            dateDone: "",
            userDone: "",
            checked: false
          },
          specificity: {
            tested: "Specyficzność",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: this.user,
            strains: [
              {
                strain: "Nie dotyczy",
                visual: "brak wzrostu",
                reference: "Nie dotyczy",
                fertility: "Nie dotyczy",
                grade: true,
              },
            ],
            done: false,
            dateDone: "",
            userDone: "",
            checked: false
          },
          other: {
            tested: "Inne uwagi",
            value: "Brak uwag",
            date: "21.05.2020",
            user: this.user,
          },
        },
        additional: {
          allowedForUsage: true,
          userAllowed: this.user,
          dateAllowed: "",
          whoAllowed: "",
          checked: false
        },
      },
    };
  },
  watch: {
    "cardJson.fertilityTest.aridity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("aridity", newVal, oldVal);
      this.cardJson.additional.dateAllowed =
        this.cardJson.fertilityTest["aridity"].date;
    },
    "cardJson.fertilityTest.aridity.date": function() {
      this.cardJson.additional.dateAllowed =
        this.cardJson.fertilityTest["aridity"].date;
    },
    "cardJson.fertilityTest.fertility.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("fertility", newVal, oldVal);
    },
    "cardJson.fertilityTest.selectivity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("selectivity", newVal, oldVal);
    },
    "cardJson.fertilityTest.specificity.incubation.time": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate("specificity", newVal, oldVal);
    },

    "cardJson.fertilityTest.aridity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("aridity", newVal, oldVal);
      this.cardJson.additional.dateAllowed = newVal;
    },
    "cardJson.fertilityTest.fertility.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("fertility", newVal, oldVal);
    },
    "cardJson.fertilityTest.selectivity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("selectivity", newVal, oldVal);
    },
    "cardJson.fertilityTest.specificity.dateDone": function (
      newVal,
      oldVal
    ) {
      this.calculateFertilityCheckDate2("specificity", newVal, oldVal);
    },

    "cardJson.fertilityTest.aridity.done": function () {
      this.setFertilityUserDone("aridity" );
    },
    "cardJson.fertilityTest.fertility.done": function () {
      this.setFertilityUserDone("fertility" );
    },
    "cardJson.fertilityTest.selectivity.done": function () {
      this.setFertilityUserDone("selectivity" );
    },
    "cardJson.fertilityTest.specificity.done": function () {
      this.setFertilityUserDone("specificity");
    },
    soilCardsAll: {
      handler() {
        let candidate = null;
        // let breakLoop = false;
        // // need to filter through children and children children as well, or rather search in them
        
        // for( const lv1 of this.soilCards )
        // {
        //   if ( lv1.id == this.$route.params.cardId )
        //   {
        //     candidate = lv1;
        //     break;
        //   }
        //   if ( lv1.children.length > 0 ) {
        //     for( const lv2 of lv1.children )
        //     {
        //       if ( lv2.id == this.$route.params.cardId )
        //       {
        //         candidate = lv2;
        //         breakLoop = true;
        //         break;
        //       }
        //       if ( lv2.children.length > 0 ) {
        //         for( const lv3 of lv2.children )
        //         {
        //           if ( lv3.id == this.$route.params.cardId )
        //           {
        //             candidate = lv3;
        //             breakLoop = true;
        //             break;
        //           }
        //         }
        //       }

        //       if ( breakLoop )
        //         break;
        //     }
        //   }

        //   if ( breakLoop )
        //     break;
        // }
        // 
        candidate = this.soilCardsAll.filter( el => el.id == this.$route.params.cardId )[0]

        if (!candidate) return;

        checkObject( candidate.meta_data, this.cardJson );

        const {
          general,
          acceptanceProtocol,
          chemTest,
          fertilityTest,
          additional,
        } = candidate.meta_data;

        if (candidate)
          this.cardJson = {
            general,
            acceptanceProtocol,
            chemTest,
            fertilityTest,
            additional,
          };
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["soilCards", "soilCardsAll", "currentUserPersonalInfo"]),
    currentUser() {
      return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
    },
    cardsInputValues() {
      const referenceArray = Object.values(this.cardJson.fertilityTest).flatMap(subObj =>
        subObj.strains ? subObj.strains.map(strain => strain.reference) : []
      );
      return [
        { 
          value: "Nie dotyczy",
          text: "Nie dotyczy",
        },
        {
          value: "null",
          text: ""
        },
        ...this.soilCardsAll
          // .filter((el) => el.meta_data.general)
          // .filter((el) => el.meta_data.acceptance)
          .filter((el) => ( el.status === "in-use" && el.level == 3 ) || referenceArray.includes(el.id) ) // && el.type === "ready"
          .map((el) => {
            let text = this.getVal(el, "soilMark") + "/" + this.getVal(el, "soilSerialNo") + "/" + (new Date( Date.parse(this.getVal(el, "soilDryExpiry")) )).toLocaleDateString('pl-PL');
            return {
              value: el.id,
              text
            };
          }),
      ];
    },
    user() {
      if (this.currentUserPersonalInfo.name && !this.$route.params.cardId)
        return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
      else if (this.$route.params.cardId)
        return this.soilCardsAll.filter(
          (el) => el.id == this.$route.params.cardId
        )[0]
          ? this.soilCardsAll.filter((el) => el.id == this.$route.params.cardId)[0]
              .user.firstName +
              " " +
              this.soilCardsAll.filter(
                (el) => el.id == this.$route.params.cardId
              )[0].user.lastName
          : "";

      return "";
    },
  },
  mounted() {
    this.$store.dispatch("soilCardsList");

    // if (!this.$route.params.cardId) {
      // let today = new Date();

      // this.cardJson.acceptanceProtocol.date = today;
      // this.cardJson.chemTest.date = today;

      // for (let key in this.cardJson.fertilityTest) {
      //   // this.cardJson.fertilityTest[key].date = today;
      //   this.cardJson.fertilityTest[key].dateDone = today;
      //   // this.cardJson.fertilityTest[key].userDone = this.user;
      //   // this.cardJson.fertilityTest[key].user = this.user;
      // }

      // let k = this.cardJson.general.findIndex(
      //   (el) => el.key == "datePackageAdmitted"
      // );
      // this.cardJson.general[k].value = today;

      // this.cardJson.additional.userAllowed = this.user;
      // this.cardJson.additional.whoAllowed = this.user;
      // this.cardJson.acceptanceProtocol.person = this.user;
      // this.cardJson.chemTest.person = this.user;
    // }
  },
};
</script>

<style lang="scss" scoped>
.colVerticalCenter {
  display: grid;
  align-items: center;
}
table th,
table td {
  padding: 5px 10px;
}

table tr {
  border-bottom: 1px solid #000;
}
table td {
  border-right: 1px solid #000;
}
table tr td:last-of-type {
  border-right: none;
}

.dataTable {
  header {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;

    div {
      padding: 5px 0;
      font-weight: bold;
    }
  }
  header,
  .tableBody {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 10px;

    .tableRow {
      border-bottom: 1px solid #000;
      padding: 5px 0 20px;
      grid-column: 1 / span 8;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      align-items: center;

      &:last-of-type {
        border-bottom: none;
      }

      .tableSubRow {
        grid-column: 3 / span 5;
        display: grid;
        grid-template-columns: 30px repeat(5, 1fr);
        grid-column-gap: 10px;
      }
    }
  }
}

.span6 {
  grid-column: 2 / span 6;
}

.addSubRow {
}
</style>
