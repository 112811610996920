<template>
  <div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div
          class="col-xl-3"
          v-for="(field, index) in [ ...this.cardJson.prep.rows.filter((el) =>
            ['soilMark', 'soilShortcut', 'soilName'].includes(el.key)
          ), ...this.cardJson.acceptance.rows.filter( el => el.key == 'soilMark')]"
          v-bind:key="'some' + index"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
            ></component>
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-6 mt-6">Protokół przyjęcia pożywki:</h2>
      <b-form-checkbox 
        v-model="cardJson.acceptance.accepted"
        @change="markUser('acceptance.accepted', 'acceptance.person', 'acceptance.date' )"
      >Przyjęto</b-form-checkbox>
      <b-form-group label="Osoba przyjmująca podłoże:"> 
        <b-form-input
            v-model="cardJson.acceptance.person"
        />
      </b-form-group>
      <div class="row">
        <div
            v-for="(field, index) in this.cardJson.acceptance.rows.filter( el => el.key != 'soilMark' && el.key != 'preparationDate')"
            v-bind:key="index"
            class="col-xl-3"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component
                v-bind:is="field.component"
                v-bind="field.props"
                v-model="field.value"
            ></component>
          </b-form-group>
        </div>

        <div class="col-xxl-3 colVerticalCenter">
          <b-form-group label="Certyfikat">
            <b-form-radio
                v-model="cardJson.acceptance.certified"
                name="certified"
                value="true"
            >Tak</b-form-radio
            >
            <b-form-radio
                v-model="cardJson.acceptance.certified"
                name="certified"
                value="false"
            >Nie</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="col-xxl-3 colVericalCenter">
          <b-form-group label="Ocena opakowania">
            <b-form-radio
                v-model="cardJson.acceptance.satisfies.value"
                name="satisfies"
                value="true"
            >Spełnia oczekiwania</b-form-radio
            >
            <b-form-radio
                v-model="cardJson.acceptance.satisfies.value"
                name="satisfies"
                value="false"
            >Nie spełnia oczekiwań</b-form-radio
            >
            <b-form-input
                placeholder="Uwagi"
                v-model="cardJson.acceptance.satisfies.comment"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <h2 class="mb-6 mt-6">Protokół przygotowania pożywki:</h2>
      <b-form-checkbox 
        v-model="cardJson.prep.accepted"
        @change="markUser('prep.accepted', 'prep.person', 'prep.date' )"
      >Przygotowano</b-form-checkbox>
      <b-form-group label="Osoba przygotowująca podłoże:">
        <b-form-input
            v-model="cardJson.prep.person"
        />
      </b-form-group>
      <div class="row">
        <div
          v-for="(field, index) in this.cardJson.prep.rows.filter(
            (el) => !['soilMark', 'soilShortcut', 'soilName'].includes(el.key)
          )"
          v-bind:key="index"
          class="col-xl-3"
        >
          <b-form-group :label="field.label" :label-for="field.key">
            <component
              v-if="field.key !== 'soilExpiryDate' && field.key  !== 'soilPrepedDate' && field.key !== 'soilExpiryDays' && field.key !== 'soilAmountInLitres'"
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
            ></component>
            <component
              v-else-if="field.key === 'soilExpiryDate'"
              v-bind:is="field.component"
              v-bind="field.props"
              @change="calculateExpiryDate(index, $event)"
              v-model="field.value"
            />
            <component
              v-else-if="field.key === 'soilPrepedDate' || field.key === 'soilExpiryDays'"
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
              v-on:change="calculateExpiryDatePrep(field.key, 'prep')"
              v-on:input="calculateExpiryDatePrep(field.key, 'prep')"
            />
            <component 
              v-else-if="field.key === 'soilAmountInLitres'"
              v-bind:is="field.component"
              v-bind="field.props"
              v-model="field.value"
              v-on:change="calculateNawazka( field.key, 'prep' )"
            />
          </b-form-group>
        </div>
<!--        <div class="col-xxl-3 colVerticalCenter">-->
<!--          <b-form-group label="Certyfikat">-->
<!--            <b-form-radio-->
<!--              v-model="cardJson.prep.certified"-->
<!--              name="certified"-->
<!--              value="true"-->
<!--              >Tak</b-form-radio-->
<!--            >-->
<!--            <b-form-radio-->
<!--              v-model="cardJson.prep.certified"-->
<!--              name="certified"-->
<!--              value="false"-->
<!--              >Nie</b-form-radio-->
<!--            >-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--        <div class="col-xxl-3 colVericalCenter">-->
<!--          <b-form-group label="Ocena opakowania">-->
<!--            <b-form-radio-->
<!--              v-model="cardJson.prep.satisfies.value"-->
<!--              name="satisfies"-->
<!--              value="true"-->
<!--              >Spełnia oczekiwania</b-form-radio-->
<!--            >-->
<!--            <b-form-radio-->
<!--              v-model="cardJson.prep.satisfies.value"-->
<!--              name="satisfies"-->
<!--              value="false"-->
<!--              >Nie spełnia oczekiwań</b-form-radio-->
<!--            >-->
<!--            <b-form-input-->
<!--              placeholder="Uwagi"-->
<!--              v-model="cardJson.prep.satisfies.comment"-->
<!--            ></b-form-input>-->
<!--          </b-form-group>-->
<!--        </div>-->
      </div>

      <h2 class="mb-6 mt-6">Warunki sterylizacji</h2>
      <div class="row">
        <div class="col-xxl-3">
          <b-form-group label="Sposób jałowienia podłoża">
            <b-form-input v-model="cardJson.prep.sterilization.method" />
          </b-form-group>
        </div>
        <div class="col-xxl-3">
          <b-form-group label="Parametry ( temp. / czas )">
            <b-form-input v-model="cardJson.prep.sterilization.params" />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-8">
      <h2 class="mb-6 mt-6">Badane właściwości fizyko-chemiczne</h2>
      <div class="row">
        <div class="col-xxl-8">
          <div class="row">
            <div class="col-xxl-12">
              <b-form-checkbox 
                v-model="cardJson.chemTest.accepted"
                @change="markUser('chemTest.accepted', 'chemTest.person', 'chemTest.date' )"
              >Sprawdzono</b-form-checkbox>
              <p class="mb-1"><b>Osoba sprawdzająca podłoże: </b></p>
              <b-form-input v-model="cardJson.chemTest.person"></b-form-input>
              <p>
                <b>Data: </b>
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="chemtestDate"
                  v-model="cardJson.chemTest.date"
                >
                </b-form-datepicker>
              </p>

              <div class="row">
                <div class="col-xxl-4">
                  <b>Parametry badane:</b>
                </div>
                <div class="col-xxl-4">
                  <b>Parametry oczekiwane:</b>
                </div>
                <div class="col-xxl-4">
                  <b>Parametry uzyskane:</b>
                </div>
              </div>

              <div
                v-for="(row, index) in this.cardJson.chemTest.rows"
                class="row"
                v-bind:key="index"
              >
                <div class="col-xxl-4 colVerticalCenter">
                  {{ row.parameter }}
                </div>
                <div class="col-xxl-4 colVerticalCenter">
                  <b-form-input
                    placeholder=""
                    v-model="row.expected"
                  ></b-form-input>
                </div>
                <div class="col-xxl-4 colVerticalCenter">
                  <b-form-group>
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="true"
                      >Tak</b-form-radio
                    >
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="false"
                      >Nie</b-form-radio
                    >
                    <b-form-radio
                      v-model="row.achieved"
                      :name="row.key"
                      value="n/a"
                      >Nie dotyczy</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-xxl-4 colVerticalCenter">
                  Spełnia oczekiwania:
                </div>
                <div class="col-xxl-8 colVerticalCenter">
                  <b-form-group>
                    <b-form-radio
                      v-model="this.cardJson.chemTest.allowed"
                      name="allowed"
                      value="true"
                      >Tak</b-form-radio
                    >
                    <b-form-radio
                      v-model="this.cardJson.chemTest.allowed"
                      name="allowed"
                      value="false"
                      >Nie</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <section class="dataTable">
        <header>
          <div>Parametry badane</div>
          <div>Parametry inkubacji</div>
          <div>Ilość sprawdzanych płytek / probówek / sztuk</div>
          <div>Ilość kontaminacji</div>
          <div>Ocena wizualna</div>
          <div>Spełnia oczekiwania / nie spełnia oczekiwań</div>
          <div>Data / sprawdził</div>
        </header>

        <section class="tableBody">
          <div
              class="tableRow"
              v-for="(row, index) in this.cardJson.bioQuality.rows"
              v-bind:key="'bio-' + index"
          >
            <div>
              {{ row.tested }}
              <b-form-group label="" v-if="index !== 'other'">
                <b-form-checkbox v-model="row.done">Wykonano</b-form-checkbox>
                <b-form-datepicker
                    v-model="row.dateDone"
                    reset-button
                    type="date"
                    placeholder="dd.mm.rrrr"
                    size="xs"
                    :start-weekday="1"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                />
                <b-form-input placeholder="Wykonał" v-model="row.userDone" />
              </b-form-group>
            </div>
            <div>
              <b-form-group label="Temp. [℃]">
                <b-form-input placeholder="" v-model="row.incubation.temp" />
              </b-form-group>
              <b-form-group label="Czas [h]">
                <b-form-input
                    type="number"
                    label="Czas"
                    placeholder=""
                    v-model="row.incubation.time"
                />
              </b-form-group>
            </div>
            <div>
              <b-form-input v-model="row.testedAmount" />
            </div>
            <div>
              <b-form-input v-model="row.contamination" />
            </div>
            <div>
              <b-form-input placeholder="" v-model="row.visual" />
            </div>
            <div>
              <b-form-select
                  :options="[
                  {
                    value: true,
                    text: 'Spełnia oczekiwania',
                  },
                  {
                    value: false,
                    text: 'Nie spełnia oczekiwań',
                  },
                ]"
                  v-model="row.grade"
              />
            </div>
            <div>
              <b-form-checkbox 
                v-model="row.accepted"
                @change="markUser( 'bioQuality.rows.' + index + '.accepted','bioQuality.rows.' + index + '.user', 'bioQuality.rows.' + index + '.date' )"
              >Sprawdzono</b-form-checkbox>
              <b-form-input v-model="row.user" />
              <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :id="index + 'specificDate'"
                  v-model="row.date"
              >
              </b-form-datepicker>
            </div>
          </div>
        </section>
      </section>
<!--      <h2 class="mb-2 mt-6">-->
<!--        Protokół badania jakości mikrobiologicznej pożywki:-->
<!--      </h2>-->
<!--      <h3 class="mb-6 mt-1">Protokół jałowości podłoża</h3>-->
<!--      <div class="row">-->
<!--        <div-->
<!--          class="col-xl-3"-->
<!--          v-for="(field, index) in cardJson.bioQuality.general"-->
<!--          v-bind:key="index"-->
<!--        >-->
<!--          <b-form-group :label="field.label" :label-for="field.key">-->
<!--            <component-->
<!--              v-bind:is="field.component"-->
<!--              v-bind="field.props"-->
<!--              v-model="field.value"-->
<!--            ></component>-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div-->
<!--        class="row"-->
<!--        v-for="(row, index) in cardJson.bioQuality.rows"-->
<!--        v-bind:key="index"-->
<!--      >-->
<!--        <div class="col-xl-12">-->
<!--          <h3>{{ row.param }}</h3>-->
<!--        </div>-->
<!--        <div-->
<!--          class="col-xl-2"-->
<!--          v-for="(field, index) in row.fields"-->
<!--          v-bind:key="index"-->
<!--        >-->
<!--          <b-form-group :label="field.label" :label-for="field.key">-->
<!--            <component-->
<!--              v-bind:is="field.component"-->
<!--              v-bind="field.props"-->
<!--              v-model="field.value"-->
<!--            ></component>-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-xl-4">-->
<!--          <b-form-group label="Dopuszczenie do użytku">-->
<!--            <b-form-radio-->
<!--              v-model="cardJson.bioQuality.allowed"-->
<!--              name="allowedKey"-->
<!--              value="true"-->
<!--              >Tak</b-form-radio-->
<!--            >-->
<!--            <b-form-radio-->
<!--              v-model="cardJson.bioQuality.allowed"-->
<!--              name="allowedKey"-->
<!--              value="false"-->
<!--              >Nie</b-form-radio-->
<!--            >-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--        <div class="col-xl-4">-->
<!--          <b-form-group label="Data">-->
<!--            <b-form-datepicker-->
<!--              placeholder="np. 12.12.2021"-->
<!--              v-model="cardJson.bioQuality.date"-->
<!--            />-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--        <div class="col-xl-4">-->
<!--          <b-form-group label="Osoba wprowadzająca">-->
<!--            <b-form-input placeholder="" v-model="cardJson.bioQuality.person" />-->
<!--          </b-form-group>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <div class="col-xxl-12">
      <h2 class="mb-2 mt-6">Występowanie cech charakterystycznych testu</h2>
      <section class="dataTable">
        <header>
          <div>Parametry badane</div>
          <div>Parametry inkubacji</div>
          <div>Szczep testowy użyty do badania</div>
          <div>Ocena wizualna</div>
          <div>Spełani oczekiwania / nie spełnia oczekiwań</div>
          <div>Data / wykonał</div>
        </header>
        <section class="tableBody">
          <div
            v-for="(row, index) in this.cardJson.characterData"
            v-bind:key="'fertility' + index"
            class="tableRow"
          >
            <div>
              {{ row.tested }}
              <b-form-group label="" v-if="index !== 'other' && index !== 'allowed'">
                <b-form-checkbox v-model="row.done">Wykonano</b-form-checkbox>
                <b-form-datepicker
                  v-model="row.dateDone"
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                />
                <b-form-input placeholder="Wykonał" v-model="row.userDone" />
              </b-form-group>
            </div>
            <template v-if="index !== 'other' && index !== 'allowed'">
              <div>
                <b-form-group label="Temp. [℃]">
                  <b-form-input placeholder="" v-model="row.incubation.temp" />
                </b-form-group>
                <b-form-group label="Czas [h]">
                  <b-form-input
                    type="number"
                    label="Czas"
                    placeholder=""
                    v-model="row.incubation.time"
                  />
                </b-form-group>
              </div>
              <section
                class="tableSubRow"
                v-for="(subRow, subIndex) in row.strains"
                v-bind:key="'sub' + subIndex"
              >
                <div>
                  <a
                    class="btn btn-icon btn-light btn-sm addSubRow"
                    @click="addStrain(index)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Code/Plus.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <a
                    class="btn btn-icon btn-light btn-sm removeSubRow"
                    v-if="row.strains.length > 1"
                    @click="removeStrain(index, subIndex)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/General/Trash.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                </div>
                <div>
                  <b-form-textarea
                    placeholder="np. Listeria monocytogenes 4b WDCM 00021"
                    v-model="subRow.strain"
                    rows="1"
                  />
                </div>
                <div>
                  <b-form-textarea rows="1" placeholder="" v-model="subRow.visual" />
                </div>
                <div>
                  <b-form-select
                    :options="[
                      {
                        value: true,
                        text: 'Spełnia oczekiwania',
                      },
                      {
                        value: false,
                        text: 'Nie spełnia oczekiwań',
                      },
                    ]"
                    v-model="subRow.grade"
                  />
                </div>
              </section>
              <div>
                <b-form-checkbox 
                  v-model="row.accepted"
                  @change="markUser( 'characterData.'+ index +'.accepted','characterData.'+ index +'.user', 'characterData.'+ index +'.date' )"
                >Sprawdzono</b-form-checkbox>
                <b-form-input
                  placeholder="Osoba uzupełniająca"
                  v-model="row.user"
                ></b-form-input>
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  :id="index + 'specificDate'"
                  v-model="row.date"
                >
                </b-form-datepicker>
              </div>
            </template>
            <template v-if="index === 'other'">
              <div class="span6">
                <b-form-textarea v-model="row.value" />
              </div>
              <div>
                <b-form-checkbox 
                  v-model="row.accepted"
                  @change="markUser( 'characterData.'+ index +'.accepted','characterData.'+ index +'.user', 'characterData.'+ index +'.date' )"
                >Uzupełniono</b-form-checkbox>
                <b-form-input
                  v-model="row.user"
                  placeholder="Osoba uzupełniająca"
                ></b-form-input>
                <b-form-datepicker
                  reset-button
                  type="date"
                  placeholder="dd.mm.rrrr"
                  size="xs"
                  :start-weekday="1"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  id="otherspecificDate"
                  v-model="row.date"
                >
                </b-form-datepicker>
              </div>
            </template>
          </div>
        </section>
      </section>
      <div class="row">
        <div class="col-xl-3">
          <b-form-group label="Dopuszczenie do użytku">
            <b-form-radio
              v-model="cardJson.characterData.allowed.isAllowed"
              name="allowedKey2"
              value="true"
              >Tak</b-form-radio
            >
            <b-form-radio
              v-model="cardJson.characterData.allowed.isAllowed"
              name="allowedKey2"
              value="false"
              >Nie</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Data">
            <b-form-datepicker
              reset-button
              type="date"
              placeholder="dd.mm.rrrr"
              size="xs"
              :start-weekday="1"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              v-model="cardJson.characterData.allowed.date"
            />
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Dopuścił">
            <b-form-checkbox
              v-model="cardJson.characterData.allowed.allowedChecked"
              @change="marUserEnd('characterData.allowed.allowedChecked', 'characterData.allowed.user')"
            >Dopuść</b-form-checkbox>
            <b-form-input
              placeholder=""
              v-model="cardJson.characterData.allowed.user" 
            />
          </b-form-group>
        </div>
        <div class="col-xl-3">
          <b-form-group label="Zatwierdził">
            <b-form-checkbox
              v-model="cardJson.characterData.allowed.acceptedChecked"
              @change="marUserEnd('characterData.allowed.acceptedChecked', 'characterData.allowed.who')"
            >Zatwierdź</b-form-checkbox>
            <b-form-input
              placeholder=""
              v-model="cardJson.characterData.allowed.who" 
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div class="col-xxl-12">
      <b-button @click="saveCard" variant="primary" class="mt-3"
        >Zapisz pożywkę</b-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import checkObject from '@/core/services/checkObject'

export default {
  name: "TestSoilForm",
  emits: ["saveCard", "savePrepared"],
  data() {
    return {
      cardJson: {
        acceptance: {
          person: "",
          certified: true,
          satisfies: {
            value: true,
            comment: "",
          },
          rows: [
            {
              label: "Oznaczenie podłoża",
              key: "soilMark",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilMark",
                placeholder: "",
              },
            },
            {
              label: "Data przyjęcia opakowania",
              key: "soilAdmissionDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilAdmissionDate",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Cena netto za opakowanie [zł]",
              key: "soilNetPrice",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilNetPrice",
                placeholder: "",
              },
            },
            {
              label: "Ilość opakowań [szt]",
              key: "soilPackageCount",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilPackageCount",
                placeholder: "",
              },
            },
            {
              label: "Vat [%]",
              key: "soilVatRate",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilVatRate",
                placeholder: "",
              },
            },
            {
              label: "Producent",
              key: "soilManufacturer",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilManufacturer",
                placeholder: "",
              },
            },
            {
              label: "Dostawca",
              key: "soilSuplier",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilSuplier",
                placeholder: "",
              },
            },
            {
              label: "Partia podłoża",
              key: "soilSerialNo",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilSerialNo",
                placeholder: "",
              },
            },
            {
              label: "Data ważności podłoża",
              key: "soilDryExpiry",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilDryExpiry",
                placeholder: "",
                "reset-button": true,
                type: "date",

                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Data przygotowania",
              key: "preparationDate",
              component: "b-form-input",
              value: "",
              props: {
                id: "preparationDate",
                placeholder: "np. 20-05-2020",
              },
            },
          ],
        },
        prep: {
          certified: true,
          satisfies: {
            value: true,
            comment: "",
          },
          sterilization: {
            method: "",
            params: ""
          },
          person: "",
          accepted: "",
          rows: [
            // {
            //   label: "Oznaczenie podłoża",
            //   key: "soilMark",
            //   component: "b-form-input",
            //   value: "PM - 106",
            //   props: {
            //     id: "soilMark",
            //     placeholder: "",
            //   },
            // },
            {
              label: "Skrót",
              key: "soilShortcut",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilShortcut",
                placeholder: "",
              },
            },
            {
              label: "Nazwa podłoża",
              key: "soilName",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilName",
                placeholder: "",
              },
            },
            {
              label: "Data otwarcia opakowania",
              key: "soilPacakgeOpenedDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilPacakgeOpenedDate",
                placeholder: "np. 15.11.2022",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Data przygotowania podłoża",
              key: "soilPrepedDate",
              component: "b-form-datepicker", 
              value: "",
              props: {
                id: "soilPrepedDate",
                placeholder: "np. 15.11.2022",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            // {
            //   label: "Cena netto za opakowanie",
            //   key: "soilNetPrice",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilNetPrice",
            //     placeholder: "np. 150zł",
            //   },
            // },
            // {
            //   label: "Ilość opakowań",
            //   key: "soilPackageCount",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilPackageCount",
            //     placeholder: "np. 5",
            //   },
            // },
            // {
            //   label: "Vat",
            //   key: "soilVatRate",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilVatRate",
            //     placeholder: "np. 8%",
            //   },
            // },
            {
              label: "Ważność podłoża [ w dniach ]",
              key: "soilExpiryDays",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilExpiryDays",
                type: "number",
              },
            },
            {
              label: "Data ważności sporządzonego podłoża podłoża",
              key: "prepedSoilExpiryDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "prepedSoilExpiryDate",
                placeholder: "",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            
            // {
            //   label: "Data ważności sporządzonego podłoża",
            //   key: "soilExpiryDate",
            //   component: "b-form-datepicker",
            //   value: "",
            //   props: {
            //     id: "soilExpiryDate",
            //     placeholder: "",
            //     "date-format-options": {
            //       year: "numeric",
            //       month: "numeric",
            //       day: "numeric",
            //     },
            //   },
            // },
            {
              label: "Naważka [ g ]",
              key: "soilWeightInGrams",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilWeightInGrams",
                placeholder: "",
              },
            },
            {
              label: "Sporządzona objętość [ L ]",
              key: "soilAmountInLitres",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilAmountInLitres",
                placeholder: "",
              },
            },
            {
              label: "standardowa naważka w gramach na litr",
              key: "soilStandardAmountGperL",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilStandardAmountGperL",
                placeholder: "",
              },
            },
            // Tutaj dodac pole na "standardowa naważka na litr", dodatkowo dodać wyliczanie nawazki na podstawie objętości i vice versa
            {
              label: "Dozowanie [ ml ]",
              key: "soilDosage",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilDosage",
                placeholder: "",
              },
            },
            {
              label: "Odczyt z dejonizatora",
              key: "soilIoniserReading",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilIoniserReading",
                placeholder: "",
              },
            },
            // {
            //   label: "Producent",
            //   key: "soilManufacturer",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilManufacturer",
            //     placeholder: "np. Oxoid",
            //   },
            // },
            // {
            //   label: "Dostawca",
            //   key: "soilSuplier",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilSuplier",
            //     placeholder: "np. Argente",
            //   },
            // },
            // {
            //   label: "Partia podłoża",
            //   key: "soilSerialNo",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilSerialNo",
            //     placeholder: "np. CD125",
            //   },
            // },
            // {
            //   label: "Data ważności",
            //   key: "soilDryExpiry",
            //   component: "b-form-datepicker",
            //   value: "",
            //   props: {
            //     id: "soilDryExpiry",
            //     placeholder: "np. 25.11.2022",
            //     "date-format-options": {
            //       year: "numeric",
            //       month: "numeric",
            //       day: "numeric",
            //     },
            //   },
            // },
          ],
        },
        chemTest: {
          date: "20-03-2019",
          allowed: true,
          person: "",
          rows: [
            {
              parameter: "Kolor podłoża",
              expected: "",
              achieved: true,
              key: "color",
            },
            {
              parameter: "Klarowność",
              expected: "",
              achieved: true,
              key: "clarity",
            },
            {
              parameter: "Obecność strątów",
              expected: "",
              achieved: true,
              key: "precipitates",
            },
            {
              parameter: "Jednorodność",
              expected: "",
              achieved: true,
              key: "homogeneity",
            },
            {
              parameter: "Konsystencja",
              expected: "",
              achieved: true,
              key: "consistency",
            },
            {
              parameter: "Grubość warstwy[mm] / objętość[ml]",
              expected: "",
              achieved: true,
              key: "size",
            },
            {
              parameter: "Inne uwagi",
              expected: "",
              achieved: false,
              key: "other",
            },
          ],
        },
        bioQuality: {
          allowed: true,
          date: "12-12-2021",
          person: "",
          general: [
            {
              label: "Data przygotowania podłoża",
              key: "soilPreparedDate",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilPreparedDate",
                placeholder: "np. -",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            {
              label: "Data ważności podłoża po przygotowaniu",
              key: "soilExpirydDateAfterPrep",
              component: "b-form-datepicker",
              value: "",
              props: {
                id: "soilExpirydDateAfterPrep",
                placeholder: "np. -",
                "reset-button": true,
                type: "date",
                size: "xs",
                "start-weekday": "1",
                "date-format-options": {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }
              },
            },
            // {
            //   label: "Data rozpuszczenia podłoża",
            //   key: "soilDissolutionDate",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilDissolutionDate",
            //     placeholder: "np. -",
            //   },
            // },
            // {
            //   label: "Data ważności podłoża po rozlaniu na płytki",
            //   key: "soilExpiryDateAfterTest",
            //   component: "b-form-input",
            //   value: "",
            //   props: {
            //     id: "soilExpiryDateAfterTest",
            //     placeholder: "np. -",
            //   },
            // },
          ],
          rows: {
            aridity: {
              tested: "Jałowość",
              incubation: {
                temp: "",
                time: "",
              },
              date: "21.05.2022",
              user: "",
              testedAmount: "",
              contamination: "",
              visualGrade: "",
              grade: true,
              done: false,
              dateDone: "",
              userDone: "",
            },
            // {
            //   param: "Jałowość",
            //   fields: [
            //     {
            //       label: "Data rozpoczęcia",
            //       key: "dateStarted",
            //       component: "b-form-datepicker",
            //       value: "",
            //       props: {
            //         id: "dateStarted",
            //         placeholder: "np. -",
            //       },
            //     },
            //     {
            //       label: "Parametry inkubacji temp./czas",
            //       key: "incubationParams",
            //       component: "b-form-input",
            //       value: "44 +/- 4h / 37 +/- 1 C",
            //       props: {
            //         id: "incubationParams",
            //         placeholder: "np. -",
            //       },
            //     },
            //     {
            //       label: "Ilość sprawdzonych płytek/probówek/sztuk",
            //       key: "testedAmount",
            //       component: "b-form-input",
            //       value: "1",
            //       props: {
            //         id: "testedAmount",
            //         placeholder: "np. -",
            //       },
            //     },
            //     // {
            //     //   label: "Ocena wizualna",
            //     //   key: "visualGrade",
            //     //   component: "b-form-input",
            //     //   value: "",
            //     //   props: {
            //     //     id: "visualGrade",
            //     //     placeholder: "np. -",
            //     //   },
            //     // },
            //     {
            //       label: "Ilość kontaminacji",
            //       key: "contaminations",
            //       component: "b-form-input",
            //       value: "0",
            //       props: {
            //         id: "contaminations",
            //         placeholder: "np. -",
            //       },
            //     },
            //     {
            //       label: "Ocena",
            //       key: "grade",
            //       component: "b-form-input",
            //       value: "+",
            //       props: {
            //         id: "grade",
            //         placeholder: "np. -",
            //       },
            //     },
            //     {
            //       label: "Osoba uzupełniająca",
            //       key: "personResponsible",
            //       component: "b-form-input",
            //       value: "",
            //       props: {
            //         id: "personResponsible",
            //         placeholder: "np. -",
            //       },
            //     },
            //     {
            //       label: "Data zakkończenia",
            //       key: "dateEnded",
            //       component: "b-form-datepicker",
            //       value: "",
            //       props: {
            //         id: "dateEnded",
            //         placeholder: "np. -",
            //       },
            //     },
            //   ],
            // },
          },
        },
        characterData: {
          a: {
            tested: "Charakterystyczne reakcje ze szczepem specyficznym",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: this.user,
            strain: "Listeria monocytogenes 44b WDCM 00021",
            visualGrade: "Aglutynacja +++",
            strains: [
              {
                strain: "",
                visual: "",
                reference: "Nie dotyczy",
                fertility: "Nie dotyczy",
                grade: true,
              },
            ],
            grade: "+",
            done: false,
            dateDone: "",
            userDone: "",
          },
          b: {
            tested: "Charakterystyczne reakcje ze szczepem nie specyficznym",
            incubation: {
              temp: "",
              time: "",
            },
            date: "21.05.2020",
            user: this.user,
            strain: "Listeria monocytogenes 1/2a WDCM 00109 ATCC 35152",
            visualGrade: "Brak aglutynacji",
            strains: [
              {
                strain: "",
                visual: "",
                reference: "Nie dotyczy",
                fertility: "Nie dotyczy",
                grade: true,
              },
            ],
            grade: "+",
            done: false,
            dateDone: "",
            userDone: "",
          },
          other: {
            tested: "Inne uwagi",
            value: "BRAK",
            date: "21.05.2020",
            user: this.user,
          },
          allowed: {
            isAllowed: true,
            date: "",
            user: this.user,
            who: ''
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["soilCards","soilCardsAll", "currentUserPersonalInfo"]),
    currentUser() {
      return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
    },
    user() {
      if (this.currentUserPersonalInfo.name && !this.$route.params.cardId)
        return (
          this.currentUserPersonalInfo.name +
          " " +
          this.currentUserPersonalInfo.surname
        );
      else if (this.$route.params.cardId)
        return this.soilCardsAll.filter(
          (el) => el.id == this.$route.params.cardId
        )[0]
          ? this.soilCardsAll.filter((el) => el.id == this.$route.params.cardId)[0]
              .user.firstName +
              " " +
              this.soilCardsAll.filter(
                (el) => el.id == this.$route.params.cardId
              )[0].user.lastName
          : "";

      return "";
    },
  },
  methods: {
    setValueByNestedKey(obj, nestedKey, value) {
      const keys = nestedKey.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, key) => {
        // Check if the key is a numerical index and whether it points to an array
        if (!isNaN(key) && (Array.isArray(o))) {
          // Ensure the array is large enough
          key = parseInt(key, 10);
          while (key >= o.length) {
            o.push({});
          }
          return o[key];
        }
        // If not, proceed with an object
        o[key] = o[key] || {};
        return o[key];
      }, obj);  
      lastObj[lastKey] = value;
      this.$forceUpdate();
    },
    getValueByNestedKey(obj, nestedKey) {
      const keys = nestedKey.split('.');
      const lastKey = keys.pop();
      const lastObj = keys.reduce((o, key) => {
        // Check if the key is a numerical index and whether it points to an array
        if (!isNaN(key) && (Array.isArray(o))) {
          // Ensure the array is large enough
          key = parseInt(key, 10);
          while (key >= o.length) {
            o.push({});
          }
          return o[key];
        }
        // If not, proceed with an object
        o[key] = o[key] || {};
        return o[key];
      }, obj);  
      
      return lastObj[lastKey];
    },
    markUser( doneKey, userKey, dateKey ) {
      let today = new Date();
      let user = this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname;

      if ( this.getValueByNestedKey( this.cardJson, doneKey ) )
      {
        this.setValueByNestedKey( this.cardJson, userKey, user );
        if ( !dateKey.includes('bioQuality') && !dateKey.includes('characterData') )
          this.setValueByNestedKey( this.cardJson, dateKey, today );

        if (dateKey.includes('characterData.other'))
          this.setValueByNestedKey( this.cardJson, dateKey, today );
      }
      else
      {
        this.setValueByNestedKey( this.cardJson, userKey, "" ); 
        if ( !dateKey.includes('bioQuality') && !dateKey.includes('characterData') )
          this.setValueByNestedKey( this.cardJson, dateKey, "" );

        if (dateKey.includes('characterData.other'))
          this.setValueByNestedKey( this.cardJson, dateKey, "" );
      }


      // let today = new Date();
      // let user = this.currentUserPersonalInfo.name +
      //   " " +
      //   this.currentUserPersonalInfo.surname;

      // if ( !this.getValueByNestedKey(this.cardJson, userKey) || this.getValueByNestedKey(this.cardJson, userKey).trim().length == 0 )
      //   this.setValueByNestedKey( this.cardJson, userKey, user );
      // else
      //   this.setValueByNestedKey( this.cardJson, userKey, "" ); 

      // if ( !dateKey.includes('bioQuality') && !dateKey.includes('characterData') )
      // {
      //   if ( !this.getValueByNestedKey(this.cardJson, dateKey))
      //     this.setValueByNestedKey( this.cardJson, dateKey, today );
      //   else
      //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
      // }

      // if (dateKey.includes('characterData.other'))
      // {
      //   if ( !this.getValueByNestedKey(this.cardJson, dateKey) )
      //     this.setValueByNestedKey( this.cardJson, dateKey, today );
      //   else
      //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
      // }
      // // ====
      // if ( event )
      // {
      //   let today = new Date();
      //   let user = this.currentUserPersonalInfo.name +
      //     " " +
      //     this.currentUserPersonalInfo.surname;
      //   this.setValueByNestedKey( this.cardJson, userKey, user );

      //   if ( !dateKey.includes('bioQuality') && !dateKey.includes('characterData') )
      //     this.setValueByNestedKey( this.cardJson, dateKey, today );
      // }
      // else
      // {
      //   this.setValueByNestedKey( this.cardJson, userKey, "" );
      //   if ( !dateKey.includes('bioQuality') && !dateKey.includes('characterData') )
      //     this.setValueByNestedKey( this.cardJson, dateKey, "" );
      // }
    },
    marUserEnd(doneKey, userKey) {
      let user = this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname;

      if ( this.getValueByNestedKey( this.cardJson, doneKey ) )
      {
        this.setValueByNestedKey( this.cardJson, userKey, user );
      }
      else
      {
        this.setValueByNestedKey( this.cardJson, userKey, "" ); 
      }
    },
    setBioUserDone(row) {
      let today = new Date();
      if (this.cardJson.bioQuality.rows[row].done ) {
        this.cardJson.bioQuality.rows[row].userDone = this.currentUser;
        this.cardJson.bioQuality.rows[row].dateDone = today;
        // this.cardJson.bioQuality.rows[row].user = this.user;
      } else {
        this.cardJson.bioQuality.rows[row].userDone = "";
        this.cardJson.bioQuality.rows[row].dateDone = "";
      }
    },
    calculateExpiryDatePrep(fieldKey, dataPoint) {
      if (
        fieldKey !== "soilPrepedDate" &&
        fieldKey !== "soilExpiryDays" &&
        dataPoint !== "prep"
      )
        return;

      const days = this.cardJson.prep.rows.filter(
        (el) => el.key === "soilExpiryDays"
      )[0].value;
      const d1 = new Date(this.cardJson.prep.rows.filter(
          (el) => el.key === "soilPrepedDate"
      )[0].value);

      const milliseconds = d1.getTime() + 3600000 * 24 * days;
      const d2 = new Date(milliseconds);

      const k = this.cardJson.prep.rows.findIndex(
        (el) => el.key === "prepedSoilExpiryDate"
      );
      this.cardJson.prep.rows[k].value = d2;
    },
    calculateNawazka(fieldKey, dataPoint) {
      if (
        fieldKey !== "soilStandardAmountGperL" &&
        fieldKey !== "soilWeightInGrams" &&
        fieldKey !== "soilAmountInLitres" &&
        dataPoint !== 'prep'
      )
        return;

      let soilStandardAmountGperL = this.cardJson.prep.rows.filter(
        el => el.key == 'soilStandardAmountGperL'
      );

      if ( soilStandardAmountGperL.length === 0 )
        return;

      soilStandardAmountGperL = parseFloat( soilStandardAmountGperL[0].value.trim().replace(',', '.') );

      const soilAmountInLitres = this.cardJson.prep.rows.filter(
        el => el.key == 'soilAmountInLitres'
      )[0].value.trim().replace(',', '.');

      if ( soilAmountInLitres.length == 0 )
        return;

      let weighInGrams = parseFloat( soilAmountInLitres ) * soilStandardAmountGperL;

      const k = this.cardJson.prep.rows.findIndex(
        (el) => el.key === "soilWeightInGrams"
      );
      this.cardJson.prep.rows[k].value = weighInGrams.toFixed(2);
    },
    saveCard() {
      this.$emit("saveCard", this.cardJson);
    },
    markPrepared() {
      this.$emit("savePrepared", this.cardJson);
    },
    addStrain(rowIndex) {
      this.cardJson.characterData[rowIndex].strains.push({
        strain: "Nie dotyczy",
        visual: "brak wzrostu",
        reference: "Nie dotyczy",
        fertility: "Nie dotyczy",
        grade: true,
      });
    },
    removeStrain(rowIndex, subRowIndex) {
      this.cardJson.characterData[rowIndex].strains.splice(subRowIndex, 1);
    },
    calculateExpiryDate(index, event) {
      let days = parseInt(event);
      let prepDate = this.cardJson.prep.rows.filter(
        (el) => el.key === "soilPrepedDate"
      )[0];
      let expiryDate = new Date(
        new Date(prepDate.value).getTime() + days * 24 * 60 * 60 * 1000
      );

      this.cardJson.prep.rows[index].value =
        expiryDate.getFullYear() +
        "-" +
        expiryDate.getMonth() +
        "-" +
        expiryDate.getDate();
    },
    calculateFertilityCheckDate(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        const d1 = new Date(this.cardJson.characterData[row].dateDone);
        const milliseconds = d1.getTime() + 3600000 * newVal;
        const d2 = new Date(milliseconds);
        this.cardJson.characterData[row].date = d2;
      }
    },
    calculateFertilityCheckDate2(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        let time = parseInt(this.cardJson.characterData[row].incubation.time);
      
        if ( time ) {
          const d1 = new Date(newVal);
          const milliseconds = d1.getTime() + 3600000 * time;
          const d2 = new Date(milliseconds);
          this.cardJson.characterData[row].date = d2;
        }
      }
    },
    setFertilityUserDone(row) {
      let today = new Date;
      if (this.cardJson.characterData[row].done ) {
        this.cardJson.characterData[row].userDone = this.currentUser;
        this.cardJson.characterData[row].dateDone = today;
      } else {
        this.cardJson.characterData[row].userDone = "";
        this.cardJson.characterData[row].dateDone = "";
      }
    },
    calculateBioCheckDate(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        const d1 = new Date(this.cardJson.bioQuality.rows[row].dateDone);
        const milliseconds = d1.getTime() + 3600000 * newVal;
        const d2 = new Date(milliseconds);
        this.cardJson.bioQuality.rows[row].date = d2;
      }
    },
    calculateBioCheckDate2(row, newVal, oldVal) {
      if (newVal !== oldVal) {
        let time = parseInt(this.cardJson.bioQuality.rows[row].incubation.time);
        if ( time ) {
          const d1 = new Date(newVal);
          const miliseconds = d1.getTime() + 3600000 * time;
          const d2 = new Date(miliseconds);
          this.cardJson.bioQuality.rows[row].date = d2;
        }
      }
    }
  },
  watch: {
    "cardJson.bioQuality.rows.aridity.incubation.time": function (
        newVal,
        oldVal
    ) {
      this.calculateBioCheckDate("aridity", newVal, oldVal);
      // this.cardJson.characterData.allowed.date =
      //     this.cardJson.bioQuality.rows["aridity"].date;
    },

    "cardJson.bioQuality.rows.aridity.dateDone": function (
        newVal,
        oldVal
    ) {
      this.calculateBioCheckDate2("aridity", newVal, oldVal);
      // console.log('aaa')
      
    },

    "cardJson.bioQuality.rows.aridity.date": function() 
    {
      this.cardJson.characterData.allowed.date =
          this.cardJson.bioQuality.rows.aridity.date;
    },

    "cardJson.bioQuality.rows.aridity.done": function () {
      this.setBioUserDone("aridity");
    },
    "cardJson.characterData.a.incubation.time": function (newVal, oldVal) {
      this.calculateFertilityCheckDate("a", newVal, oldVal);
      // this.cardJson.characterData.allowed.date =
      //   this.cardJson.characterData["a"].date;
    },
    "cardJson.characterData.b.incubation.time": function (newVal, oldVal) {
      this.calculateFertilityCheckDate("b", newVal, oldVal);
    },

    "cardJson.characterData.a.dateDone": function (newVal, oldVal) {
      this.calculateFertilityCheckDate2("a", newVal, oldVal);
      // this.cardJson.characterData.allowed.date =
      //   this.cardJson.characterData["a"].date;
    },
    "cardJson.characterData.b.dateDone": function (newVal, oldVal) {
      this.calculateFertilityCheckDate2("b", newVal, oldVal);
    },


    "cardJson.characterData.a.done": function () {
      this.setFertilityUserDone("a");
    },
    "cardJson.characterData.b.done": function () {
      this.setFertilityUserDone("b");
    },
    soilCardsAll: {
      handler() {
        let candidate = null;
        // let breakLoop = false;
        // need to filter through children and children children as well, or rather search in them
        
        // for( const lv1 of this.soilCards )
        // {
        //   if ( lv1.id == this.$route.params.cardId )
        //   {
        //     candidate = lv1;
        //     break;
        //   }
        //   if ( lv1.children.length > 0 ) {
        //     for( const lv2 of lv1.children )
        //     {
        //       if ( lv2.id == this.$route.params.cardId )
        //       {
        //         candidate = lv2;
        //         breakLoop = true;
        //         break;
        //       }

        //       if ( lv2.children.legnth > 0 ) {
        //         for( const lv3 of lv2.children )
        //         {
        //           if ( lv3.id == this.$route.params.cardId )
        //           {
        //             candidate = lv3;
        //             breakLoop = true;
        //             break;
        //           }
        //         }
        //       }

        //       if ( breakLoop )
        //         break;
        //     }
        //   }

        //   if ( breakLoop )
        //     break;
        // }
        // 
        candidate = this.soilCardsAll.filter( el => el.id == this.$route.params.cardId )[0]

        if (!candidate ) return;

        checkObject( candidate.meta_data, this.cardJson );

        const { acceptance, prep, chemTest, bioQuality, characterData } =
          candidate.meta_data;

        if (candidate)
        {
          this.cardJson = {
            acceptance,
            prep,
            chemTest,
            bioQuality,
            characterData,
          };

          if ( prep.rows.filter( el => el.key == 'soilWeightInGrams' ).length == 0 )
            prep.rows.push({
              label: "Naważka [ g ]",
              key: "soilWeightInGrams",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilWeightInGrams",
                placeholder: "",
              },
            });

          if ( prep.rows.filter( el => el.key == 'soilAmountInLitres' ).length == 0 )
            prep.rows.push({
              label: "Sporządzona objętość [ L ]",
              key: "soilAmountInLitres",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilAmountInLitres",
                placeholder: "",
              },
            })

          if ( prep.rows.filter( el => el.key == 'soilStandardAmountGperL' ).length == 0 )
            prep.rows.push({
              label: "standardowa naważka w gramach na litr",
              key: "soilStandardAmountGperL",
              component: "b-form-input",
              value: "",
              props: {
                id: "soilStandardAmountGperL",
                placeholder: "",
              },
            })


          if ( this.cardJson.characterData.allowed.who == undefined )
            this.cardJson.characterData.allowed.who = '';
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.$store.dispatch("soilCardsList");

    // if (!this.$route.params.cardId) {
      // this.cardJson.characterData.allowed.user = this.user;
      // this.cardJson.characterData.allowed.who = this.user;
      // this.cardJson.characterData.allowed.date = new Date();
  
    // }
  },
};
</script>

<style lang="scss" scoped>
.colVerticalCenter {
  display: grid;
  align-items: center;
}
table th,
table td {
  padding: 5px 10px;
}

table tr {
  border-bottom: 1px solid #000;
}
table td {
  border-right: 1px solid #000;
}
table tr td:last-of-type {
  border-right: none;
}

.dataTable {
  header {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;

    div {
      padding: 5px 0;
      font-weight: bold;
    }
  }
  header,
  .tableBody {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 10px;

    .tableRow {
      border-bottom: 1px solid #000;
      padding: 5px 0 20px;
      grid-column: 1 / span 8;
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      align-items: center;

      &:last-of-type {
        border-bottom: none;
      }

      .tableSubRow {
        grid-column: 3 / span 5;
        display: grid;
        grid-template-columns: 30px repeat(5, 1fr);
        grid-column-gap: 10px;
      }
    }
  }
}

.span6 {
  grid-column: 2 / span 6;
}

.addSubRow {
}
</style>
